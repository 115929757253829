import { Button } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import { useSelector } from "react-redux";
import { validateMerchant } from "../../services/applePayService";
import "./ApplePay.css";
import {
  encryptApplePaymentTrx,
  handleCCAvenuePayment,
} from "../../services/CCAvenueService";
import { PaymentOperationIds } from "../../constants/operationIds";
import { LamdaFnLinks, PaymentChannels } from "../../constants/enums";
import { getCurrentTip } from "../tipsComponent/TipsComponent";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
import { toast } from "react-toastify";
import { createPaymentRequestService } from "../../services/payment";
import {
  allItemsAreOfferItems,
  displayPrices,
  getEquivalentAED,
  isDiscountItemLoyaltyV2,
  offersKeywords,
} from "../../helpers/utils";
import DontCloseModal from "../DontCloseModal/DontCloseModal";
import { useState } from "react";
const ApplePayButton = () => {
  const concept = useSelector((state) => state.app.conceptOptions);
  /* Payment Process */
  // for deployment
  const currency = "AED";
  const method = "https://apple.com/apple-pay";
  // Production
  const merchantIdentifier = concept.applePayMerchantIdentifier;
  const initiativeContext = "qr.sosocial.com";
  const registered = useSelector((state) => state.app.registered);

  // Sandbox
  // const merchantIdentifier = "merchant.qr.sosocial.com.sandbox";
  // const initiativeContext = "cm-apple-pay.vercel.app";
  // const initiativeContext = "applepay.d2b9h53elhpr6y.amplifyapp.com";

  const { tip, order, paymentType, currentDue, selectedMenuItems } =
    useSelector((state) => state.order);
  const table = useSelector((state) => state.table.table);
  const user = useSelector((state) => state.app.user);
  const usedCash = useSelector((state) => state.order.usedCash);
  var request = null;
  var paymentDetails = null;

  async function onApplePayButtonTapped() {
    await createPaymentRequest();

    if (!request) {
      // toast.error("Failed to create Apple payment request.", {
      //   autoClose: false,
      // });
      setDontCloseModal(false);
      return;
    }

    try {
      // Payment Request Events
      request.onmerchantvalidation = async (event) => {
        try {
          // Call your own server to request a new merchant session.
          const merchantSessionPromise = await validateMerchant(
            concept,
            merchantIdentifier,
            initiativeContext
          );
          if (merchantSessionPromise === undefined) {
            // toast.error(`Failed to verify your Apple Pay. Please try again`, {
            //   autoClose: false,
            // });
          } else {
            event.complete(merchantSessionPromise);
          }
        } catch (e) {
          console.error(e);
          // toast.error(`Session Error: ${JSON.stringify(e)}`, {
          //   autoClose: false,
          // });
          setDontCloseModal(false);
        }
      };

      request.onpaymentmethodchange = (event) => {
        // event: isTrusted: true
        if (event.methodDetails.type !== undefined) {
          // Define PaymentDetailsUpdate based on the selected payment method.
          // No updates or errors needed, pass an object with the same total.
          const paymentDetailsUpdate = {
            total: paymentDetails.total,
          };
          event.updateWith(paymentDetailsUpdate);
        } else if (event.methodDetails.couponCode !== undefined) {
          // Define PaymentDetailsUpdate based on the coupon code.
          const total = calculateTotal(event.methodDetails.couponCode);
          const displayItems = calculateDisplayItem(
            event.methodDetails.couponCode
          );
          const shippingOptions = calculateShippingOptions(
            event.methodDetails.couponCode
          );
          const error = calculateError(event.methodDetails.couponCode);

          event.updateWith({
            total: total,
            displayItems: displayItems,
            shippingOptions: shippingOptions,
            modifiers: [
              {
                data: {
                  additionalShippingMethods: shippingOptions,
                },
              },
            ],
            error: error,
          });
        }
      };

      request.onshippingoptionchange = (event) => {
        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total,
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = (event) => {
        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {};
        event.updateWith(paymentDetailsUpdate);
      };

      // Payment Failed
      request.onpaymentauthorized = async (event) => {
        // toast.error(`Payment Auth ${JSON.stringify(event)}`, {
        //   autoClose: false,
        // });
        setDontCloseModal(false);
      };

      // Payment Cancelled
      request.oncancel = (event) => {
        // toast.error(`Payment Cancelled`, { autoClose: false });
        setDontCloseModal(false);
      };
    } catch (e) {
      console.error(e);
      // toast.error(`Error: ${JSON.stringify(e)}`, { autoClose: false });
      setDontCloseModal(false);
    }

    const response = await request.show();
    const status = "success";
    response.complete(status);
    // toast.success(`Payment Success:${JSON.stringify(response)}`, {
    //   autoClose: false,
    // });
    console.log(`Apple Pay Response : ${JSON.stringify(response)}`);

    if (!response) {
      // toast.error("Issue processing your payment. Please try again.", {
      //   autoClose: false,
      // });
      setDontCloseModal(false);
      return;
    }

    const bankResponse = await callCCAvenue(response);

    if (bankResponse) {
      const { access_code, encRequest } = bankResponse;
      await handleCCAvenuePayment(
        access_code,
        encRequest,
        PaymentChannels.APPLE
      );
    } else {
      // toast.error(`Failed to encrypt CCAvenue request`, {
      //   autoClose: false,
      // });
      setDontCloseModal(false);
    }
  }

  function createPaymentRequest() {
    try {
      // Define PaymentDetails
      paymentDetails = {
        total: {
          label: `${concept.name} Receipt#${order.CheckNumber} - Table#${table.name}`,
          amount: {
            value: displayPrices(
              currentDue +
                displayPrices(
                  calcWithServiceFees({
                    total: currentDue,
                    serviceFee: concept?.serviceFee,
                  }).serviceFee
                ) +
                tip -
                getEquivalentAED(usedCash, user?.soSocialConversion)
            ),
            currency: currency,
          },
        },
      };

      if (
        paymentType === "partial" &&
        selectedMenuItems &&
        selectedMenuItems.length > 0
      ) {
        let items = [];
        for (let item of selectedMenuItems) {
          items.push({
            label: item.Name,
            amount: {
              value: displayPrices(item.totalAmount),
              currency: currency,
            },
          });
        }

        paymentDetails.displayItems = items;
      }

      // Define PaymentMethodData
      const paymentMethodData = [
        {
          supportedMethods: method,
          data: {
            version: 14,
            merchantIdentifier: merchantIdentifier,
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: ["masterCard", "visa"], // "amex", "discover",
            countryCode: "AE",
          },
        },
      ];

      // Define PaymentOptions
      const paymentOptions = {
        requestPayerName: true,
        requestBillingAddress: false,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: false,
        shippingType: "shipping", // shipping, delivery, storePickup, servicePickup
      };
      console.log({ paymentMethodData, paymentDetails, paymentOptions });
      console.log({ paymentMethodData, paymentDetails, paymentOptions });

      request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions
      );

      return request;
    } catch (error) {
      console.log(`Create Apple Request Error: ${JSON.stringify(error)}`);
      // toast.error(`Create Apple Request Error: ${JSON.stringify(error)}`, {
      //   autoClose: false,
      // });
      setDontCloseModal(false);
    }
  }

  const callCCAvenue = async (response) => {
    try {
      const channel = PaymentChannels.APPLE;
      const operation = PaymentOperationIds.CCAvenue_PAYMENT_STATUS;
      const redirectURL = `${
        LamdaFnLinks.PaymentOperations
      }?operation=${operation}&channel=${channel}&conceptID=${
        table?.conceptID
      }&source=${registered ? "app" : "website"}`;
      let host = window.location.hostname;

      /* Prepare Simphony Payload */
      const items = selectedMenuItems
        ? selectedMenuItems.map((item) => {
            return {
              DetailLink: item.DetailLink,
              qty: item.SalesCount,
            };
          })
        : undefined;

      const paymentDetails = {
        // SoSocial Info
        usedCash,
        user: user ? user._id : null,
        percentageArray: user?.percentageArray,
        soSocialConversion: user?.soSocialConversion,
        discountAmount:
          (order?.DiscountItems &&
            order?.DiscountItems.length > 0 &&
            Math.abs(
              order.DiscountItems.reduce(
                (i, item) => i + displayPrices(Number(item.Total)),
                0
              )
            )) ||
          0,
        isOffer: items
          ? selectedMenuItems
            ? allItemsAreOfferItems(selectedMenuItems)
            : false
          : allItemsAreOfferItems(order.MenuItems),
        offerAmount: items
          ? selectedMenuItems
            ? selectedMenuItems
                .filter((item) =>
                  offersKeywords.some((name) =>
                    item.Name?.toLowerCase().includes(name)
                  )
                )
                .reduce((i, item) => i + displayPrices(Number(item.Total)), 0)
            : false
          : order.MenuItems.filter((item) =>
              offersKeywords.some((name) =>
                item.Name?.toLowerCase().includes(name)
              )
            ).reduce((i, item) => i + displayPrices(Number(item.Total)), 0),
        familyGroupAmount: 0,
        hasDiscounts:
          order?.DiscountItems && order?.DiscountItems.length > 0
            ? !isDiscountItemLoyaltyV2(
                order?.DiscountItems,
                concept?.discountObjectNum
              )
            : false,
        conceptId: table?.conceptID,
        tableSimphonyId: table?.simphonyID,
        CheckNum: order?.CheckNumber,
        CheckSeq: order?.CheckSeqNumber,
        payment: {
          type: paymentType,
          amount: currentDue,
          billAmount: order?.Due, // Total Due
          tips: getCurrentTip({
            tip,
            currentDue,
            total: order?.Due,
          }),
          serviceCharge: displayPrices(
            calcWithServiceFees({
              total: currentDue,
              serviceFee: concept?.serviceFee,
            }).serviceFee
          ),
          items,
          userEmail: user ? user.email : "", // Need to get email from Apple Response
          paymentMethod: PaymentChannels.APPLE,
        },
      };

      const paymentRequestID = await createPaymentRequestService(
        paymentDetails
      );
      console.log(`paymentRequestID: ${paymentRequestID}`);
      /* Call server to send CCAvenue pay request */
      const paymentTransaction = {
        merchant_id: "", // Will be add by server side
        order_id: order ? order.CheckId : "",
        currency: currency,
        amount: displayPrices(
          currentDue +
            calcWithServiceFees({
              total: currentDue,
              serviceFee: concept ? concept.serviceFee : null,
            }).serviceFee +
            tip -
            getEquivalentAED(usedCash, user ? user.soSocialConversion : 1)
        ),
        redirect_url: redirectURL,
        cancel_url: redirectURL,
        language: "EN",

        // Additional Information
        merchant_param1: host,
        merchant_param2: order?.id,
        merchant_param3: table?.name,
        merchant_param4: table?.tableCode,
        merchant_param5: paymentRequestID, // Will use it to update order on DB and Simphony
      };
      // Got payment encryption request
      const bankResponse = await encryptApplePaymentTrx(
        concept,
        paymentTransaction,
        response
      );
      return bankResponse;
    } catch (e) {
      console.error(e);
      // toast.error(`Prepare CCAvenue Error: ${JSON.stringify(e)}`, {
      //   autoClose: false,
      // });
    }
  };

  // test
  function calculateTotal() {}
  function calculateDisplayItem() {}
  function calculateShippingOptions() {}
  function calculateError() {}

  const [dontCloseModal, setDontCloseModal] = useState(false);
  const handleConfirm = () => {
    setDontCloseModal(false);
    onApplePayButtonTapped();
  };

  return (
    <>
      <DontCloseModal open={dontCloseModal} onOk={handleConfirm} loading />
      {window.ApplePaySession && window.ApplePaySession.canMakePayments() && (
        <Button
          sx={classes.finalPayment}
          onClick={async () => {
            // setDontCloseModal(true);
            // Set a timer to auto-close after 30 seconds
            setTimeout(() => {
              setDontCloseModal(false);
            }, 30000);
            await onApplePayButtonTapped();
            setDontCloseModal(false);
          }}
        >
          <>
            Pay With
            <AppleIcon />
            Pay
          </>
        </Button>
      )}
    </>
  );

  // Original Doc UI
  // return (
  //   <div>
  //     <apple-pay-button
  //       buttonstyle="black"
  //       type="pay"
  //       locale="en-US"
  //       // onclick={onApplePayButtonTapped()}
  //     ></apple-pay-button>
  //   </div>
  // );
};

const classes = {
  finalPayment: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    color: "white",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
};

export default ApplePayButton;
