import {
  Backdrop,
  Box,
  Button,
  Grid,
  Slide,
  Typography,
  createTheme,
} from "@mui/material";

import lightThemeOptions from "../../theme/lightThemeOptions";
import { useState } from "react";
import { StoreState } from "../../store/ducks";
import { useSelector } from "react-redux";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
import CustomTipModal from "../tipsComponent/CustomInputModal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  displayPrices,
  formatWithCommas,
  getEquivalentAED,
  getEquivalentSosocial,
} from "../../helpers/utils";
import { FaPencilAlt } from "react-icons/fa";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  setCash: (cash: number) => void;
  onClose: () => void;
};
function RedemptionModal({ open, setCash, onClose }: Props) {
  const user = useSelector((state: StoreState) => state.app.user);
  const wallet_cash = Number(user?.wallet.amount);
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const { currentDue, order, tip } = useSelector(
    (state: StoreState) => state.order
  );
  const currentDueInSosocial = getEquivalentSosocial(
    calcWithServiceFees({
      total: currentDue,
      serviceFee: conceptOptions?.serviceFee!,
    }).total,
    user?.soSocialConversion
  );
  const walletInAed = getEquivalentAED(wallet_cash, user?.soSocialConversion);
  // console.log(walletInAed);
  const maxBill = displayPrices(
    currentDueInSosocial > wallet_cash
      ? walletInAed
      : calcWithServiceFees({
          total: currentDue,
          serviceFee: conceptOptions?.serviceFee!,
        }).total
  );
  const [customInput, setCustomInput] = useState<boolean>(false);

  const handleCloseThis = () => {

    if (customInput) return;
    onClose();
  };
  const handleSetCash = (cash: number) => {
    setCustomInput(false);
    setCash(cash);
  };

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
          backgroundColor: "unset",
        }}
        open={open}
        onClick={(e) => handleCloseThis()}
      >
        <CustomTipModal
          open={customInput}
          handleSubmit={handleSetCash}
          onClose={() => setCustomInput(false)}
          max={displayPrices(
            getEquivalentSosocial(maxBill, user?.soSocialConversion)
          )}
        />
        <Slide in={open} direction="up">
          <Box
            // paddingBottom: "300px"
            sx={{ ...classes.containerBox }}
            onClick={(e) => e.stopPropagation()}
          >
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                xs={12}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      fontSize={15}
                      fontWeight={"bold"}
                      sx={{ width: "100%", color: "#606060" }}
                    >
                      Use SoSocial Wallet
                    </Typography>
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleCloseThis}
                      size={"24px"}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid #C4C4C4",
                    borderRadius: "32px",
                    padding: "14px",
                  }}
                >
                  <Box sx={{ mb: "16px", mt: "12px" }}>
                    <Box sx={{ justifyContent: "space-between", mb: "16px" }}>
                      <Typography
                        fontWeight={"bold"}
                        fontSize={14}
                        sx={{
                          color: "#606060",
                          "@media (max-width: 390px)": {
                            fontSize: "12px", // Example adjustment for small screens
                          },
                        }}
                      >
                        Account Name: {user?.first_name} {user?.last_name}
                      </Typography>{" "}
                      <Typography
                        fontSize={14}
                        fontWeight={"bold"}
                        sx={{
                          color: "#606060",
                          "@media (max-width: 390px)": {
                            fontSize: "12px",
                          },
                        }}
                      >
                        Current Balance :{" "}
                        <Typography fontSize={"inherit"} component={"span"}>
                          {formatWithCommas(
                            displayPrices(Number(wallet_cash.toFixed(0)))
                          )}{" "}
                          SoSocial Cash
                        </Typography>
                      </Typography>
                    </Box>

                    {/* Bill Amount Row */}
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={9}>
                        <Typography
                          fontSize={14}
                          sx={{
                            color: "#606060",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                          }}
                        >
                          Bill Amount:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          fontSize={14}
                          sx={{
                            color: "#606060",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                            textAlign: "end",
                          }}
                        >
                          AED{" "}
                          {displayPrices(
                            calcWithServiceFees({
                              total: currentDue,
                              serviceFee: conceptOptions?.serviceFee!,
                            }).total
                          )}
                          {/* {formatWithCommas(
                            displayPrices(
                              calcWithServiceFees({
                                total: currentDue,
                                serviceFee: conceptOptions?.serviceFee!,
                              }).total
                            )
                          )} */}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Saving Row */}
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={9}>
                        <Typography
                          fontSize={14}
                          sx={{
                            color: "#606060",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                          }}
                        >
                          Use{" "}
                          {displayPrices(
                            getEquivalentSosocial(
                              maxBill,
                              user?.soSocialConversion
                            )
                          )}{" "}
                          Social Cash, You Save:
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          fontSize={14}
                          //   fontWeight={"bold"}
                          sx={{
                            color: "#606060",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                            textAlign: "end",
                          }}
                        >
                          -AED{" "}
                          {displayPrices(
                            calcWithServiceFees({
                              total: currentDue,
                              serviceFee: conceptOptions?.serviceFee!,
                            }).total
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Payable Amount Row */}
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={9}>
                        <Typography
                          fontSize={14}
                          //   fontWeight={"bold"}
                          sx={{
                            color: "#606060",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                          }}
                        >
                          Payable Amount:
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          fontSize={14}
                          //   fontWeight={"bold"}
                          sx={{
                            color: "#606060",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                            textAlign: "end",
                          }}
                        >
                          AED{" "}
                          {displayPrices(
                            calcWithServiceFees({
                              total: currentDue,
                              serviceFee: conceptOptions?.serviceFee!,
                            }).total - maxBill
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Note Row */}
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          fontSize={14}
                          fontWeight={"medium"}
                          sx={{
                            color: "#606060",
                            "@media (max-width: 390px)": {
                              fontSize: "10px", // Example adjustment for small screens
                            },
                            textAlign: "end",
                          }}
                        >
                          Excluding Tips
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Action Buttons */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    {/* Edit Action */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Align items vertically
                        alignItems: "center",
                        cursor: Boolean(Number(user?.wallet.amount) < 1)
                          ? "not-allowed"
                          : "pointer",
                      }}
                      onClick={() => {
                        if (Number(user?.wallet.amount) >= 1) {
                          setCustomInput(true);
                        }
                      }}
                    >
                      {/* Icon Section */}
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border:
                            "2px solid " + lightTheme.palette.secondary.main,
                          borderRadius: "50%",
                          marginBottom: "5px", // Space between icon and text
                          position: "relative",
                        }}
                      >
                        <FaPencilAlt
                          color={lightTheme.palette.secondary.main}
                          size={"12px"} // Adjust size for better visibility
                        />
                      </Box>
                      {/* Text Section */}
                      <Typography
                        sx={{
                          fontSize: "10px", // Adjust font size if needed
                          color: lightTheme.palette.text.primary,
                          textAlign: "center",
                        }}
                      >
                        Custom SoSocial Amount
                      </Typography>
                    </Box>

                    {/* Proceed Action */}
                    <Button
                      onClick={() => {
                        if (
                          user?.wallet.amount! >
                          getEquivalentSosocial(
                            maxBill,
                            user?.soSocialConversion
                          )
                        ) {
                          setCash(
                            getEquivalentSosocial(
                              maxBill,
                              user?.soSocialConversion
                            )
                          );
                        } else {
                          setCash(user?.wallet.amount!);
                        }
                      }}
                      variant="contained"
                      sx={{
                        ...classes.useAmountButton,
                        "@media (max-width: 390px)": {
                          fontSize: "10px", // Example adjustment for small screens
                          padding: "12px 8px", // Adjust padding for smaller screens
                        },
                      }}
                      disabled={Boolean(Number(user?.wallet.amount) < 1)}
                    >
                      Proceed
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: "35px",
    borderRadius: "50px 50px 0px 0px",
    boxShadow: "0px 0px 1px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  useAmountButton: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    py: "13px",
    width: "48%",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  customAmountButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    py: "10px",
    width: "48%",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
};
export default RedemptionModal;
