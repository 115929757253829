import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  createTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { Order, ServiceFeeInput } from "../../models/API";
import { MoneyTypes } from "../../constants/enums";
import { setInitial, setPayment } from "../../store/ducks/order";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { displayPrices } from "../../helpers/utils";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
const lightTheme = createTheme(lightThemeOptions);

export const calcWithServiceFees = ({
  total,
  serviceFee,
}: // serviceCharge,
{
  total: number;
  serviceFee: ServiceFeeInput;
  // serviceCharge: number;
}) => {
  if (!serviceFee) return { total: total, serviceFee: 0 };
  if (serviceFee.type === MoneyTypes.PERCENTAGE) {
    const newTotal = total + total * (Number(serviceFee.amount) / 100);
    const serviceFees = total * (Number(serviceFee.amount) / 100);

    return {
      total: displayPrices(newTotal),
      serviceFee: displayPrices(serviceFees),
    };
  } else {
    return {
      total: total + Number(serviceFee.amount),
      serviceFee: Number(serviceFee.amount),
    };
  }
};
interface OrderedItemsProps {
  withSelection?: boolean;
  TotalSetter?: any;
}

const InvoiceTable: React.FC<OrderedItemsProps> = () => {
  const { t, i18n } = useTranslation();
  const { order, total } = useSelector((state: StoreState) => state.order)!;
  const user = useSelector((state: StoreState) => state.app.user);
  const { serviceFee } = useSelector(
    (state: StoreState) => state.app.conceptOptions
  )!;
  const [serviceFeeValue, setServiceFeeValue] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const serviceFeeValue =
      calcWithServiceFees({
        total: order?.Due!,
        serviceFee,
      }).total - order?.Due!;
    setServiceFeeValue(serviceFeeValue);
    const serviceCharge = order && order.Tax ? order.Tax : 0;
    dispatch(
      setInitial(
        calcWithServiceFees({
          total: order?.totalOrderAmount!,
          serviceFee,
        }).total
      )
    );
    dispatch(
      setPayment(
        calcWithServiceFees({
          total: order?.totalOrderAmount!,
          serviceFee,
        })
      )
    );
  }, [order?.totalOrderAmount]);
  // const initialTotal = useSelector((state: StoreState) => state.order.initial);
  const getItemsArray = (order: Order | null) => {
    let itemsArray: any[] = [];
    if (!order) return itemsArray;
    if (order.MenuItems && order.MenuItems.length > 0) {
      itemsArray = [...order.MenuItems];
    }
    if (order.DiscountItems && order.DiscountItems.length > 0) {
      itemsArray = [...itemsArray, ...order.DiscountItems];
    }
    return itemsArray;
  };

  return (
    <>
      <TableContainer>
        <Table size="medium" aria-label="a dense table" key={total}>
          <TableBody>
            {getItemsArray(order)
              .sort((a, b) => parseInt(a.DetailLink) - parseInt(b.DetailLink))
              .map((item, i) => {
                return (
                  <>
                    <TableRow
                      key={item.DetailLink}
                      onClick={() => {}}
                      sx={{
                        cursor: "default",
                        borderColor: lightTheme.palette.primary.main,
                        borderStyle: "solid",
                        borderWidth: "1px 0px 1px 0px",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          padding: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor:
                                item.DetailType === "DtlMiType"
                                  ? lightTheme.palette.primary.light
                                  : "transparent",
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              fontSize={12}
                              color={lightTheme.palette.secondary.main}
                              fontWeight={"medium"}
                            >
                              {item.DetailType === "DtlMiType" &&
                                item.SalesCount}
                            </Typography>
                          </Box>
                          <Typography
                            fontSize={12}
                            color={
                              !(item.DetailType === "DtlMiType")
                                ? "#789E75"
                                : lightTheme.palette.secondary.main
                            }
                            fontWeight={"medium"}
                            textAlign={"left"}
                          >
                            {item.Name}{" "}
                            {item.DetailType === "DtlMiType" && (
                              <>
                                @{" "}
                                {/* <Typography
                                  component={"span"}
                                  fontSize={12}
                                  fontWeight={"medium"}
                                  color={
                                    !(item.DetailType === "DtlMiType")
                                      ? "#789E75"
                                      : lightTheme.palette.secondary.main
                                  }
                                >
                                  AED&nbsp;
                                </Typography> */}
                                {item.price?.toFixed(2)}
                              </>
                            )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        align={i18n.language === "Arabic" ? "left" : "right"}
                        style={{
                          color: "#000",
                        }}
                        sx={{
                          padding: 1,
                        }}
                      >
                        <Typography
                          component={"span"}
                          fontSize={12}
                          fontWeight={"medium"}
                          color={
                            !(item.DetailType === "DtlMiType")
                              ? "#789E75"
                              : lightTheme.palette.secondary.main
                          }
                        >
                          AED&nbsp;
                        </Typography>
                        <Typography
                          fontSize={12}
                          fontWeight={"medium"}
                          component={"span"}
                          color={
                            !(item.DetailType === "DtlMiType")
                              ? "#789E75"
                              : lightTheme.palette.secondary.main
                          }
                        >{`${item.totalAmount!.toFixed(2)}`}</Typography>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}

            {/* Service Fee Row */}
            {/* <TableRow sx={{ width: "100%" }}>
              <TableCell colSpan={2}>
                <Typography
                  sx={{
                    textAlign: i18n.language === "Arabic" ? "right" : "left",
                    fontWeight: "medium",
                    color: lightTheme.palette.secondary.main,
                    fontSize: "10px",
                    marginLeft: "30px",
                  }}
                >
                  Service Fees
                </Typography>
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  height: "10px",
                }}
              >
                <Typography
                  sx={{
                    height: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "right",
                      fontWeight: "medium",
                      color: lightTheme.palette.secondary.main,
                      fontSize: "10px",
                    }}
                  >
                    <Typography
                      component={"span"}
                      fontSize={10}
                      color={lightTheme.palette.secondary.main}
                      fontStyle={"medium"}
                    >
                      AED&nbsp;
                    </Typography>
                    {displayPrices(serviceFeeValue).toFixed(2)}{" "}
                    {/* {displayPrices(
                    calcWithServiceFees({
                      total: order?.Due!,
                      serviceFee,
                    }).total - order?.Due!
                  ).toFixed(2)}{" "}
                </Typography>
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Service Fee */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginTop: "5px",
        }}
      >
        {/* Service Fees Text */}
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "medium",
            color: lightTheme.palette.secondary.main,
            fontSize: "10px",
            marginLeft: "39px",
            flexGrow: 1, // Ensures the left text occupies available space
          }}
        >
          Service Fees
        </Typography>

        {/* Service Fee Value */}
        <Typography
          sx={{
            textAlign: "right",
            fontWeight: "bold",
            color: lightTheme.palette.secondary.main,
            fontSize: "10px",
            marginRight: "8px",
          }}
        >
          <Typography
            component={"span"}
            fontSize={10}
            color={lightTheme.palette.secondary.main}
            fontStyle={"medium"}
            sx={{
              fontWeight: "bold",
            }}
          >
            AED&nbsp;
          </Typography>
          {displayPrices(
            calcWithServiceFees({
              total: order?.Due!,
              serviceFee,
            }).total - order?.Due!
          ).toFixed(2)}
        </Typography>
      </Box>

      {/* Micros Service Charge */}
      {order !== null &&
        order.serviceCharge !== null &&
        order.serviceCharge !== undefined &&
        order.serviceCharge > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "5px",
            }}
          >
            {/* Service Charge Text */}
            <Typography
              sx={{
                textAlign: "left",
                fontWeight: "medium",
                color: lightTheme.palette.secondary.main,
                fontSize: "10px",
                marginLeft: "39px",
                flexGrow: 1, // Ensures the left text occupies available space
              }}
            >
              {t(TranslationsEnum.serviceCharge)}
            </Typography>

            {/* Service Charge Value */}
            <Typography
              sx={{
                textAlign: "right",
                fontWeight: "bold",
                color: lightTheme.palette.secondary.main,
                fontSize: "10px",
                marginRight: "8px",
              }}
            >
              <Typography
                component={"span"}
                fontSize={10}
                color={lightTheme.palette.secondary.main}
                fontStyle={"medium"}
                sx={{
                  fontWeight: "bold",
                }}
              >
                AED&nbsp;
              </Typography>
              {order.serviceCharge.toFixed(2)}
            </Typography>
          </Box>
        )}
    </>
  );
};

export default InvoiceTable;
