/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    mainBg {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    domain
    siteTitle
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    accountLogoId
    accountMainBgId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      logo {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        __typename
      }
      mainBg {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        __typename
      }
      domain
      siteTitle
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      accountLogoId
      accountMainBgId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const getConcept = /* GraphQL */ `query GetConcept($id: ID!) {
  getConcept(id: $id) {
    id
    accountID
    name
    description
    logo
    mainBg
    type
    location
    precedence
    longitude
    latitude
    openingHours
    closingHours
    posID
    simphonyIP
    rvcIP
    simphonyEmployeeID
    tenderID
    visaTenderID
    applePayTenderID
    samsungPayTenderID
    tipsNum
    CheckOrderType
    emailTemplateName
    discountObjectNum
    secondaryTender
    EnableLoyalty
    emailName
    emailLocation
    emailPhone
    tips {
      id
      amount
      type
      default
      __typename
    }
    serviceFee {
      amount
      type
      __typename
    }
    soSocialEarnRatio
    soSocialRedeemRatio
    paymentMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    refundMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    applePayMerchantIdentifier
    isMPGS
    retryAfter
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConceptQueryVariables,
  APITypes.GetConceptQuery
>;
export const listConcepts = /* GraphQL */ `query ListConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      description
      logo
      mainBg
      type
      location
      precedence
      longitude
      latitude
      openingHours
      closingHours
      posID
      simphonyIP
      rvcIP
      simphonyEmployeeID
      tenderID
      visaTenderID
      applePayTenderID
      samsungPayTenderID
      tipsNum
      CheckOrderType
      emailTemplateName
      discountObjectNum
      secondaryTender
      EnableLoyalty
      emailName
      emailLocation
      emailPhone
      tips {
        id
        amount
        type
        default
        __typename
      }
      serviceFee {
        amount
        type
        __typename
      }
      soSocialEarnRatio
      soSocialRedeemRatio
      paymentMerchants {
        channel
        merchant_id
        access_code
        enc_key
        url
        env
        __typename
      }
      refundMerchants {
        channel
        merchant_id
        access_code
        enc_key
        url
        env
        __typename
      }
      applePayMerchantIdentifier
      isMPGS
      retryAfter
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConceptsQueryVariables,
  APITypes.ListConceptsQuery
>;
export const getUserConcepts = /* GraphQL */ `query GetUserConcepts($id: ID!) {
  getUserConcepts(id: $id) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserConceptsQueryVariables,
  APITypes.GetUserConceptsQuery
>;
export const listUserConcepts = /* GraphQL */ `query ListUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserConceptsQueryVariables,
  APITypes.ListUserConceptsQuery
>;
export const getAttachment = /* GraphQL */ `query GetAttachment($id: ID!) {
  getAttachment(id: $id) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttachmentQueryVariables,
  APITypes.GetAttachmentQuery
>;
export const listAttachments = /* GraphQL */ `query ListAttachments(
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttachmentsQueryVariables,
  APITypes.ListAttachmentsQuery
>;
export const getFeature = /* GraphQL */ `query GetFeature($id: ID!) {
  getFeature(id: $id) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeatureQueryVariables,
  APITypes.GetFeatureQuery
>;
export const listFeatures = /* GraphQL */ `query ListFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeaturesQueryVariables,
  APITypes.ListFeaturesQuery
>;
export const getAdminRole = /* GraphQL */ `query GetAdminRole($id: ID!) {
  getAdminRole(id: $id) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminRoleQueryVariables,
  APITypes.GetAdminRoleQuery
>;
export const listAdminRoles = /* GraphQL */ `query ListAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminRolesQueryVariables,
  APITypes.ListAdminRolesQuery
>;
export const getAdminGroup = /* GraphQL */ `query GetAdminGroup($id: ID!) {
  getAdminGroup(id: $id) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminGroupQueryVariables,
  APITypes.GetAdminGroupQuery
>;
export const listAdminGroups = /* GraphQL */ `query ListAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminGroupsQueryVariables,
  APITypes.ListAdminGroupsQuery
>;
export const getMedia = /* GraphQL */ `query GetMedia($id: ID!) {
  getMedia(id: $id) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMediaQueryVariables, APITypes.GetMediaQuery>;
export const listMedia = /* GraphQL */ `query ListMedia(
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMediaQueryVariables, APITypes.ListMediaQuery>;
export const getTable = /* GraphQL */ `query GetTable($id: ID!) {
  getTable(id: $id) {
    id
    tableCode
    accountID
    conceptID
    simphonyID
    name
    status
    updatedAt
    deleted
    createdAt
    createdByID
    createdByName
    currentOrder {
      id
      accountID
      conceptID
      paymentID
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      tableSimphonyID
      CheckId
      CheckNumber
      CheckSeqNumber
      CheckOpenTime
      CheckCloseTime
      totalOrderAmount
      SubTotal
      Tax
      serviceCharge
      Due
      Payment
      AdditionalPayment
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
    lastClosedAt
    lastPaymentStatus
    lastUsedCash
    tableCurrentOrderId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTableQueryVariables, APITypes.GetTableQuery>;
export const listTables = /* GraphQL */ `query ListTables(
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  listTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tableCode
      accountID
      conceptID
      simphonyID
      name
      status
      updatedAt
      deleted
      createdAt
      createdByID
      createdByName
      currentOrder {
        id
        accountID
        conceptID
        paymentID
        paid
        serviceFee
        tips
        paidAmount
        refundedAmount
        remainingAmount
        tableSimphonyID
        CheckId
        CheckNumber
        CheckSeqNumber
        CheckOpenTime
        CheckCloseTime
        totalOrderAmount
        SubTotal
        Tax
        serviceCharge
        Due
        Payment
        AdditionalPayment
        TableOpenTime
        usedCash
        deleted
        createdAt
        updatedAt
        __typename
      }
      lastClosedAt
      lastPaymentStatus
      lastUsedCash
      tableCurrentOrderId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTablesQueryVariables,
  APITypes.ListTablesQuery
>;
export const tablesByConcept = /* GraphQL */ `query TablesByConcept(
  $conceptID: String!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  tablesByConcept(
    conceptID: $conceptID
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tableCode
      accountID
      conceptID
      simphonyID
      name
      status
      updatedAt
      deleted
      createdAt
      createdByID
      createdByName
      currentOrder {
        id
        accountID
        conceptID
        paymentID
        paid
        serviceFee
        tips
        paidAmount
        refundedAmount
        remainingAmount
        tableSimphonyID
        CheckId
        CheckNumber
        CheckSeqNumber
        CheckOpenTime
        CheckCloseTime
        totalOrderAmount
        SubTotal
        Tax
        serviceCharge
        Due
        Payment
        AdditionalPayment
        TableOpenTime
        usedCash
        deleted
        createdAt
        updatedAt
        __typename
      }
      lastClosedAt
      lastPaymentStatus
      lastUsedCash
      tableCurrentOrderId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TablesByConceptQueryVariables,
  APITypes.TablesByConceptQuery
>;
export const tablesByConceptSortedByUpdatedAt = /* GraphQL */ `query TablesByConceptSortedByUpdatedAt(
  $conceptID: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  tablesByConceptSortedByUpdatedAt(
    conceptID: $conceptID
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tableCode
      accountID
      conceptID
      simphonyID
      name
      status
      updatedAt
      deleted
      createdAt
      createdByID
      createdByName
      currentOrder {
        id
        accountID
        conceptID
        paymentID
        paid
        serviceFee
        tips
        paidAmount
        refundedAmount
        remainingAmount
        tableSimphonyID
        CheckId
        CheckNumber
        CheckSeqNumber
        CheckOpenTime
        CheckCloseTime
        totalOrderAmount
        SubTotal
        Tax
        serviceCharge
        Due
        Payment
        AdditionalPayment
        TableOpenTime
        usedCash
        deleted
        createdAt
        updatedAt
        __typename
      }
      lastClosedAt
      lastPaymentStatus
      lastUsedCash
      tableCurrentOrderId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TablesByConceptSortedByUpdatedAtQueryVariables,
  APITypes.TablesByConceptSortedByUpdatedAtQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    accountID
    conceptID
    paymentID
    paid
    serviceFee
    tips
    paidAmount
    refundedAmount
    remainingAmount
    tableSimphonyID
    CheckId
    CheckNumber
    CheckSeqNumber
    CheckOpenTime
    CheckCloseTime
    totalOrderAmount
    SubTotal
    Tax
    serviceCharge
    Due
    Payment
    AdditionalPayment
    TableOpenTime
    MenuItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      totalAmount
      price
      __typename
    }
    DiscountItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      pcnt
      totalAmount
      price
      __typename
    }
    usedCash
    ratings {
      id
      rating
      __typename
    }
    deleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      conceptID
      paymentID
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      tableSimphonyID
      CheckId
      CheckNumber
      CheckSeqNumber
      CheckOpenTime
      CheckCloseTime
      totalOrderAmount
      SubTotal
      Tax
      serviceCharge
      Due
      Payment
      AdditionalPayment
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const orderByConcept = /* GraphQL */ `query OrderByConcept(
  $conceptID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  orderByConcept(
    conceptID: $conceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      paymentID
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      tableSimphonyID
      CheckId
      CheckNumber
      CheckSeqNumber
      CheckOpenTime
      CheckCloseTime
      totalOrderAmount
      SubTotal
      Tax
      serviceCharge
      Due
      Payment
      AdditionalPayment
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrderByConceptQueryVariables,
  APITypes.OrderByConceptQuery
>;
export const listOrdersByDeleted = /* GraphQL */ `query ListOrdersByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrdersByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      paymentID
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      tableSimphonyID
      CheckId
      CheckNumber
      CheckSeqNumber
      CheckOpenTime
      CheckCloseTime
      totalOrderAmount
      SubTotal
      Tax
      serviceCharge
      Due
      Payment
      AdditionalPayment
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersByDeletedQueryVariables,
  APITypes.ListOrdersByDeletedQuery
>;
export const getPaymentRequest = /* GraphQL */ `query GetPaymentRequest($id: ID!) {
  getPaymentRequest(id: $id) {
    id
    usedCash
    user
    conceptId
    tableSimphonyId
    CheckNum
    CheckSeq
    payment {
      type
      amount
      billAmount
      tips
      serviceCharge
      items {
        DetailLink
        qty
        __typename
      }
      userEmail
      paymentMethod
      __typename
    }
    percentageArray
    soSocialConversion
    hasDiscounts
    discountAmount
    offerAmount
    familyGroupAmount
    isOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentRequestQueryVariables,
  APITypes.GetPaymentRequestQuery
>;
export const listPaymentRequests = /* GraphQL */ `query ListPaymentRequests(
  $filter: ModelPaymentRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      usedCash
      user
      conceptId
      tableSimphonyId
      CheckNum
      CheckSeq
      payment {
        type
        amount
        billAmount
        tips
        serviceCharge
        userEmail
        paymentMethod
        __typename
      }
      percentageArray
      soSocialConversion
      hasDiscounts
      discountAmount
      offerAmount
      familyGroupAmount
      isOffer
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentRequestsQueryVariables,
  APITypes.ListPaymentRequestsQuery
>;
export const getPayment = /* GraphQL */ `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    id
    accountID
    conceptID
    orderID
    tableID
    tableName
    channel
    tableOpenTime
    usedCash
    billAmount
    tipAmount
    serviceFees
    order_id
    tracking_id
    bank_ref_no
    order_status
    failure_message
    payment_mode
    card_name
    status_code
    status_message
    currency
    Amount
    billing_name
    billing_address
    billing_city
    billing_state
    billing_zip
    billing_country
    billing_tel
    billing_email
    merchant_param1
    merchant_param2
    merchant_param3
    merchant_param4
    merchant_param5
    merchant_param6
    mer_amount
    eci_value
    card_holder_name
    bank_receipt_no
    bank_qsi_no
    updatedSimphony
    updatedDBOrder
    errorMessage
    updatedSosocialWallet
    retryExecutionArn
    refunded
    refundedAmount
    percentageArray
    soSocialConversion
    earned
    isRegistered
    deleted
    CheckNum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentQueryVariables,
  APITypes.GetPaymentQuery
>;
export const listPayments = /* GraphQL */ `query ListPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      conceptID
      orderID
      tableID
      tableName
      channel
      tableOpenTime
      usedCash
      billAmount
      tipAmount
      serviceFees
      order_id
      tracking_id
      bank_ref_no
      order_status
      failure_message
      payment_mode
      card_name
      status_code
      status_message
      currency
      Amount
      billing_name
      billing_address
      billing_city
      billing_state
      billing_zip
      billing_country
      billing_tel
      billing_email
      merchant_param1
      merchant_param2
      merchant_param3
      merchant_param4
      merchant_param5
      merchant_param6
      mer_amount
      eci_value
      card_holder_name
      bank_receipt_no
      bank_qsi_no
      updatedSimphony
      updatedDBOrder
      errorMessage
      updatedSosocialWallet
      retryExecutionArn
      refunded
      refundedAmount
      percentageArray
      soSocialConversion
      earned
      isRegistered
      deleted
      CheckNum
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentsQueryVariables,
  APITypes.ListPaymentsQuery
>;
export const paymentByConcept = /* GraphQL */ `query PaymentByConcept(
  $conceptID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentByConcept(
    conceptID: $conceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      orderID
      tableID
      tableName
      channel
      tableOpenTime
      usedCash
      billAmount
      tipAmount
      serviceFees
      order_id
      tracking_id
      bank_ref_no
      order_status
      failure_message
      payment_mode
      card_name
      status_code
      status_message
      currency
      Amount
      billing_name
      billing_address
      billing_city
      billing_state
      billing_zip
      billing_country
      billing_tel
      billing_email
      merchant_param1
      merchant_param2
      merchant_param3
      merchant_param4
      merchant_param5
      merchant_param6
      mer_amount
      eci_value
      card_holder_name
      bank_receipt_no
      bank_qsi_no
      updatedSimphony
      updatedDBOrder
      errorMessage
      updatedSosocialWallet
      retryExecutionArn
      refunded
      refundedAmount
      percentageArray
      soSocialConversion
      earned
      isRegistered
      deleted
      CheckNum
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentByConceptQueryVariables,
  APITypes.PaymentByConceptQuery
>;
export const orderPayments = /* GraphQL */ `query OrderPayments(
  $orderID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  orderPayments(
    orderID: $orderID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      orderID
      tableID
      tableName
      channel
      tableOpenTime
      usedCash
      billAmount
      tipAmount
      serviceFees
      order_id
      tracking_id
      bank_ref_no
      order_status
      failure_message
      payment_mode
      card_name
      status_code
      status_message
      currency
      Amount
      billing_name
      billing_address
      billing_city
      billing_state
      billing_zip
      billing_country
      billing_tel
      billing_email
      merchant_param1
      merchant_param2
      merchant_param3
      merchant_param4
      merchant_param5
      merchant_param6
      mer_amount
      eci_value
      card_holder_name
      bank_receipt_no
      bank_qsi_no
      updatedSimphony
      updatedDBOrder
      errorMessage
      updatedSosocialWallet
      retryExecutionArn
      refunded
      refundedAmount
      percentageArray
      soSocialConversion
      earned
      isRegistered
      deleted
      CheckNum
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrderPaymentsQueryVariables,
  APITypes.OrderPaymentsQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    accountID
    conceptID
    orderID
    paymentReceipt
    rating
    review
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      conceptID
      orderID
      paymentReceipt
      rating
      review
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const reviewByConcept = /* GraphQL */ `query ReviewByConcept(
  $conceptID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewByConcept(
    conceptID: $conceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      orderID
      paymentReceipt
      rating
      review
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewByConceptQueryVariables,
  APITypes.ReviewByConceptQuery
>;
export const reviewByConceptSortedByRating = /* GraphQL */ `query ReviewByConceptSortedByRating(
  $conceptID: String!
  $rating: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewByConceptSortedByRating(
    conceptID: $conceptID
    rating: $rating
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      orderID
      paymentReceipt
      rating
      review
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewByConceptSortedByRatingQueryVariables,
  APITypes.ReviewByConceptSortedByRatingQuery
>;
export const reviewsByOrder = /* GraphQL */ `query ReviewsByOrder(
  $orderID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByOrder(
    orderID: $orderID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      orderID
      paymentReceipt
      rating
      review
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewsByOrderQueryVariables,
  APITypes.ReviewsByOrderQuery
>;
export const getUserIP = /* GraphQL */ `query GetUserIP($id: ID!) {
  getUserIP(id: $id) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserIPQueryVariables, APITypes.GetUserIPQuery>;
export const listUserIPS = /* GraphQL */ `query ListUserIPS(
  $filter: ModelUserIPFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserIPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ip
      date
      times
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserIPSQueryVariables,
  APITypes.ListUserIPSQuery
>;
export const getMainCategory = /* GraphQL */ `query GetMainCategory($id: ID!) {
  getMainCategory(id: $id) {
    id
    conceptID
    name
    precedence
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMainCategoryQueryVariables,
  APITypes.GetMainCategoryQuery
>;
export const listMainCategories = /* GraphQL */ `query ListMainCategories(
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      precedence
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMainCategoriesQueryVariables,
  APITypes.ListMainCategoriesQuery
>;
export const MainCategoryByConcept = /* GraphQL */ `query MainCategoryByConcept(
  $conceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  MainCategoryByConcept(
    conceptID: $conceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      name
      precedence
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MainCategoryByConceptQueryVariables,
  APITypes.MainCategoryByConceptQuery
>;
