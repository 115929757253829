import {
  Backdrop,
  Box,
  Grid,
  Slide,
  Typography,
  createTheme,
} from "@mui/material";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { PaymentChannels } from "../../constants/enums";
import { useEffect } from "react";
import "./styles.css";

const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  action: any;
  channel: string;
  onLoadFinish: () => void;
};
function CCAvenueModal({
  open,
  onClose,
  action,
  channel,
  onLoadFinish,
}: Props) {
  const handleClose = () => {
    onClose();
  };

  // useEffect(() => {
  // onLoadFinish();
  // });
  useEffect(() => {
    const timer = setTimeout(() => {
      onLoadFinish();
    }, 1000);

    return () => clearTimeout(timer);
  }, [onLoadFinish]);
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
        }}
        open={open}
        onClick={(e) => handleClose()}
      >
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                xs={12}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Typography sx={classes.walletTitle}>
                      Enter Card Details
                    </Typography>
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleClose}
                      size={"24px"}
                    />
                  </Box>
                  <Box sx={classes.authContainer}>
                    {/*<iframe
                      src={action}
                      width="100%"
                      height={channel === PaymentChannels.CARD ? "450" : "350"}
                      scrolling="no"
                      frameBorder="0"
                      id="paymentFrame"
                      title="Payment Frame"
                      // style={{
                      //   borderRadius: "10px",
                      //   maxWidth: "100%", // Ensures responsiveness if container is smaller
                      //   // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: Add a desktop-like shadow
                      // }}
                      onLoad={onLoadFinish}
                    ></iframe> */}
                    <iframe
                      src={action}
                      className={`payment-frame ${
                        channel === PaymentChannels.CARD ? "card" : "other"
                      }`}
                    ></iframe>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    borderRadius: "50px 50px 0px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  walletTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    color: lightTheme.palette.secondary.main,
    mb: "16px",
  },
  walletSubtitle: {
    fontWeight: "medium",
    fontSize: "14px",
    color: lightTheme.palette.secondary.main,
    width: "100%",
  },
  authContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authenticateButton: {
    backgroundColor: "black",
    width: "80%",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "32px",
    my: "18px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
  useAmountButton: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    py: "13px",
    width: "48%",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  customAmountButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    py: "10px",
    width: "48%",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  textField: {
    "& svg": { height: "1em" },
    "& .MuiOutlinedInput-root": {
      "& .MuiInputBase-input": {
        padding: "10px",
        px: "19px",
        color: lightTheme.palette.secondary.main,
        fontSize: "15px",
        fontWeight: "bold",
      },
      "& fieldset": {
        borderRadius: "32px",
        borderColor: "transparent !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
    },
  },
};
export default CCAvenueModal;
