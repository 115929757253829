/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAccountInput = {
  id?: string | null,
  domain: string,
  siteTitle: string,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  accountLogoId?: string | null,
  accountMainBgId?: string | null,
};

export type ModelAccountConditionInput = {
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  updatedAt?: ModelStringInput | null,
  accountLogoId?: ModelIDInput | null,
  accountMainBgId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  logo?: Attachment | null,
  mainBg?: Attachment | null,
  domain: string,
  siteTitle: string,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  accountLogoId?: string | null,
  accountMainBgId?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  mediaID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
};

export type UpdateAccountInput = {
  id: string,
  domain?: string | null,
  siteTitle?: string | null,
  tagline?: string | null,
  description?: string | null,
  siteAddress?: string | null,
  defaultLanguage?: string | null,
  languages?: Array< string | null > | null,
  features?: Array< string | null > | null,
  status?: string | null,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  accountLogoId?: string | null,
  accountMainBgId?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateConceptInput = {
  id?: string | null,
  accountID: string,
  name: string,
  description?: string | null,
  logo?: string | null,
  mainBg?: string | null,
  type?: string | null,
  location?: string | null,
  precedence?: number | null,
  longitude?: string | null,
  latitude?: string | null,
  openingHours?: string | null,
  closingHours?: string | null,
  posID?: string | null,
  simphonyIP?: string | null,
  rvcIP?: string | null,
  simphonyEmployeeID?: string | null,
  tenderID?: string | null,
  visaTenderID?: string | null,
  applePayTenderID?: string | null,
  samsungPayTenderID?: string | null,
  tipsNum?: string | null,
  CheckOrderType?: string | null,
  emailTemplateName?: string | null,
  discountObjectNum?: string | null,
  secondaryTender?: string | null,
  EnableLoyalty?: boolean | null,
  emailName?: string | null,
  emailLocation?: string | null,
  emailPhone?: string | null,
  tips?: Array< TipInput | null > | null,
  serviceFee?: ServiceFeeInput | null,
  soSocialEarnRatio?: number | null,
  soSocialRedeemRatio?: number | null,
  paymentMerchants?: Array< PaymentMerchantInput | null > | null,
  refundMerchants?: Array< PaymentMerchantInput | null > | null,
  applePayMerchantIdentifier?: string | null,
  isMPGS?: boolean | null,
  retryAfter?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
};

export type TipInput = {
  id: string,
  amount: number,
  type: string,
  default: boolean,
};

export type ServiceFeeInput = {
  amount: number,
  type: string,
};

export type PaymentMerchantInput = {
  channel?: string | null,
  merchant_id?: string | null,
  access_code?: string | null,
  enc_key?: string | null,
  url?: string | null,
  env?: string | null,
};

export type ModelConceptConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  mainBg?: ModelStringInput | null,
  type?: ModelStringInput | null,
  location?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  longitude?: ModelStringInput | null,
  latitude?: ModelStringInput | null,
  openingHours?: ModelStringInput | null,
  closingHours?: ModelStringInput | null,
  posID?: ModelStringInput | null,
  simphonyIP?: ModelStringInput | null,
  rvcIP?: ModelStringInput | null,
  simphonyEmployeeID?: ModelStringInput | null,
  tenderID?: ModelStringInput | null,
  visaTenderID?: ModelStringInput | null,
  applePayTenderID?: ModelStringInput | null,
  samsungPayTenderID?: ModelStringInput | null,
  tipsNum?: ModelStringInput | null,
  CheckOrderType?: ModelStringInput | null,
  emailTemplateName?: ModelStringInput | null,
  discountObjectNum?: ModelStringInput | null,
  secondaryTender?: ModelStringInput | null,
  EnableLoyalty?: ModelBooleanInput | null,
  emailName?: ModelStringInput | null,
  emailLocation?: ModelStringInput | null,
  emailPhone?: ModelStringInput | null,
  soSocialEarnRatio?: ModelFloatInput | null,
  soSocialRedeemRatio?: ModelFloatInput | null,
  applePayMerchantIdentifier?: ModelStringInput | null,
  isMPGS?: ModelBooleanInput | null,
  retryAfter?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelConceptConditionInput | null > | null,
  or?: Array< ModelConceptConditionInput | null > | null,
  not?: ModelConceptConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Concept = {
  __typename: "Concept",
  id: string,
  accountID: string,
  name: string,
  description?: string | null,
  logo?: string | null,
  mainBg?: string | null,
  type?: string | null,
  location?: string | null,
  precedence?: number | null,
  longitude?: string | null,
  latitude?: string | null,
  openingHours?: string | null,
  closingHours?: string | null,
  posID?: string | null,
  simphonyIP?: string | null,
  rvcIP?: string | null,
  simphonyEmployeeID?: string | null,
  tenderID?: string | null,
  visaTenderID?: string | null,
  applePayTenderID?: string | null,
  samsungPayTenderID?: string | null,
  tipsNum?: string | null,
  CheckOrderType?: string | null,
  emailTemplateName?: string | null,
  discountObjectNum?: string | null,
  secondaryTender?: string | null,
  EnableLoyalty?: boolean | null,
  emailName?: string | null,
  emailLocation?: string | null,
  emailPhone?: string | null,
  tips?:  Array<Tip | null > | null,
  serviceFee?: ServiceFee | null,
  soSocialEarnRatio?: number | null,
  soSocialRedeemRatio?: number | null,
  paymentMerchants?:  Array<paymentMerchant | null > | null,
  refundMerchants?:  Array<paymentMerchant | null > | null,
  applePayMerchantIdentifier?: string | null,
  isMPGS?: boolean | null,
  retryAfter?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
};

export type Tip = {
  __typename: "Tip",
  id: string,
  amount: number,
  type: string,
  default: boolean,
};

export type ServiceFee = {
  __typename: "ServiceFee",
  amount: number,
  type: string,
};

export type paymentMerchant = {
  __typename: "paymentMerchant",
  channel?: string | null,
  merchant_id?: string | null,
  access_code?: string | null,
  enc_key?: string | null,
  url?: string | null,
  env?: string | null,
};

export type UpdateConceptInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  description?: string | null,
  logo?: string | null,
  mainBg?: string | null,
  type?: string | null,
  location?: string | null,
  precedence?: number | null,
  longitude?: string | null,
  latitude?: string | null,
  openingHours?: string | null,
  closingHours?: string | null,
  posID?: string | null,
  simphonyIP?: string | null,
  rvcIP?: string | null,
  simphonyEmployeeID?: string | null,
  tenderID?: string | null,
  visaTenderID?: string | null,
  applePayTenderID?: string | null,
  samsungPayTenderID?: string | null,
  tipsNum?: string | null,
  CheckOrderType?: string | null,
  emailTemplateName?: string | null,
  discountObjectNum?: string | null,
  secondaryTender?: string | null,
  EnableLoyalty?: boolean | null,
  emailName?: string | null,
  emailLocation?: string | null,
  emailPhone?: string | null,
  tips?: Array< TipInput | null > | null,
  serviceFee?: ServiceFeeInput | null,
  soSocialEarnRatio?: number | null,
  soSocialRedeemRatio?: number | null,
  paymentMerchants?: Array< PaymentMerchantInput | null > | null,
  refundMerchants?: Array< PaymentMerchantInput | null > | null,
  applePayMerchantIdentifier?: string | null,
  isMPGS?: boolean | null,
  retryAfter?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
};

export type DeleteConceptInput = {
  id: string,
};

export type CreateUserConceptsInput = {
  id?: string | null,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  userID: string,
  createdAt?: string | null,
};

export type ModelUserConceptsConditionInput = {
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserConceptsConditionInput | null > | null,
  or?: Array< ModelUserConceptsConditionInput | null > | null,
  not?: ModelUserConceptsConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UserConcepts = {
  __typename: "UserConcepts",
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  userID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserConceptsInput = {
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  userID?: string | null,
  createdAt?: string | null,
};

export type DeleteUserConceptsInput = {
  id: string,
};

export type CreateAttachmentInput = {
  id?: string | null,
  mediaID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
};

export type ModelAttachmentConditionInput = {
  mediaID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAttachmentInput = {
  id: string,
  mediaID?: string | null,
  fileUrl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
};

export type DeleteAttachmentInput = {
  id: string,
};

export type CreateFeatureInput = {
  id?: string | null,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
};

export type ModelFeatureConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFeatureConditionInput | null > | null,
  or?: Array< ModelFeatureConditionInput | null > | null,
  not?: ModelFeatureConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Feature = {
  __typename: "Feature",
  id: string,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
};

export type UpdateFeatureInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  precedence?: string | null,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
};

export type DeleteFeatureInput = {
  id: string,
};

export type CreateAdminRoleInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
};

export type ModelAdminRoleConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminRoleConditionInput | null > | null,
  or?: Array< ModelAdminRoleConditionInput | null > | null,
  not?: ModelAdminRoleConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AdminRole = {
  __typename: "AdminRole",
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
};

export type UpdateAdminRoleInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
};

export type DeleteAdminRoleInput = {
  id: string,
};

export type CreateAdminGroupInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
};

export type ModelAdminGroupConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminGroupConditionInput | null > | null,
  or?: Array< ModelAdminGroupConditionInput | null > | null,
  not?: ModelAdminGroupConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AdminGroup = {
  __typename: "AdminGroup",
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
};

export type UpdateAdminGroupInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
};

export type DeleteAdminGroupInput = {
  id: string,
};

export type CreateMediaInput = {
  id?: string | null,
  accountID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
};

export type ModelMediaConditionInput = {
  accountID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attachments?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelMediaConditionInput | null > | null,
  or?: Array< ModelMediaConditionInput | null > | null,
  not?: ModelMediaConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Media = {
  __typename: "Media",
  id: string,
  accountID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
};

export type UpdateMediaInput = {
  id: string,
  accountID?: string | null,
  fileUrl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
};

export type DeleteMediaInput = {
  id: string,
};

export type CreateTableInput = {
  id?: string | null,
  tableCode: string,
  accountID: string,
  conceptID: string,
  simphonyID: string,
  name: string,
  status?: string | null,
  updatedAt?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  lastClosedAt?: string | null,
  lastPaymentStatus?: string | null,
  lastUsedCash?: number | null,
  tableCurrentOrderId?: string | null,
};

export type ModelTableConditionInput = {
  tableCode?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  lastClosedAt?: ModelStringInput | null,
  lastPaymentStatus?: ModelStringInput | null,
  lastUsedCash?: ModelFloatInput | null,
  and?: Array< ModelTableConditionInput | null > | null,
  or?: Array< ModelTableConditionInput | null > | null,
  not?: ModelTableConditionInput | null,
  tableCurrentOrderId?: ModelIDInput | null,
};

export type Table = {
  __typename: "Table",
  id: string,
  tableCode: string,
  accountID: string,
  conceptID: string,
  simphonyID: string,
  name: string,
  status?: string | null,
  updatedAt?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  currentOrder?: Order | null,
  lastClosedAt?: string | null,
  lastPaymentStatus?: string | null,
  lastUsedCash?: number | null,
  tableCurrentOrderId?: string | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  accountID: string,
  conceptID: string,
  paymentID?: string | null,
  paid?: boolean | null,
  serviceFee?: number | null,
  tips?: number | null,
  paidAmount?: number | null,
  refundedAmount?: number | null,
  remainingAmount?: number | null,
  tableSimphonyID: string,
  CheckId?: string | null,
  CheckNumber?: string | null,
  CheckSeqNumber?: string | null,
  CheckOpenTime?: string | null,
  CheckCloseTime?: string | null,
  totalOrderAmount?: number | null,
  SubTotal?: number | null,
  Tax?: number | null,
  serviceCharge?: number | null,
  Due?: number | null,
  Payment?: number | null,
  AdditionalPayment?: number | null,
  TableOpenTime?: string | null,
  MenuItems:  Array<MenuItem >,
  DiscountItems?:  Array<DiscountItem > | null,
  usedCash?: number | null,
  ratings?:  Array<Rating | null > | null,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type MenuItem = {
  __typename: "MenuItem",
  DetailType?: string | null,
  DetailLink: string,
  obj?: string | null,
  Total?: number | null,
  RevCtrID?: string | null,
  mTaxCollectedTotal?: number | null,
  Name?: string | null,
  SalesCount?: number | null,
  paidQTY?: number | null,
  totalAmount?: number | null,
  price?: number | null,
};

export type DiscountItem = {
  __typename: "DiscountItem",
  DetailType?: string | null,
  DetailLink: string,
  obj?: string | null,
  Total?: number | null,
  RevCtrID?: string | null,
  mTaxCollectedTotal?: number | null,
  Name?: string | null,
  SalesCount?: number | null,
  paidQTY?: number | null,
  pcnt?: number | null,
  totalAmount?: number | null,
  price?: number | null,
};

export type Rating = {
  __typename: "Rating",
  id: string,
  rating: number,
};

export type UpdateTableInput = {
  id: string,
  tableCode?: string | null,
  accountID?: string | null,
  conceptID?: string | null,
  simphonyID?: string | null,
  name?: string | null,
  status?: string | null,
  updatedAt?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  lastClosedAt?: string | null,
  lastPaymentStatus?: string | null,
  lastUsedCash?: number | null,
  tableCurrentOrderId?: string | null,
};

export type DeleteTableInput = {
  id: string,
};

export type CreateOrderInput = {
  id?: string | null,
  accountID: string,
  conceptID: string,
  paymentID?: string | null,
  paid?: boolean | null,
  serviceFee?: number | null,
  tips?: number | null,
  paidAmount?: number | null,
  refundedAmount?: number | null,
  remainingAmount?: number | null,
  tableSimphonyID: string,
  CheckId?: string | null,
  CheckNumber?: string | null,
  CheckSeqNumber?: string | null,
  CheckOpenTime?: string | null,
  CheckCloseTime?: string | null,
  totalOrderAmount?: number | null,
  SubTotal?: number | null,
  Tax?: number | null,
  serviceCharge?: number | null,
  Due?: number | null,
  Payment?: number | null,
  AdditionalPayment?: number | null,
  TableOpenTime?: string | null,
  MenuItems: Array< MenuItemInput >,
  DiscountItems?: Array< DiscountItemInput > | null,
  usedCash?: number | null,
  ratings?: Array< RatingInput | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type MenuItemInput = {
  DetailType?: string | null,
  DetailLink: string,
  obj?: string | null,
  Total?: number | null,
  RevCtrID?: string | null,
  mTaxCollectedTotal?: number | null,
  Name?: string | null,
  SalesCount?: number | null,
  paidQTY?: number | null,
  totalAmount?: number | null,
  price?: number | null,
};

export type DiscountItemInput = {
  DetailType?: string | null,
  DetailLink: string,
  obj?: string | null,
  Total?: number | null,
  RevCtrID?: string | null,
  mTaxCollectedTotal?: number | null,
  Name?: string | null,
  SalesCount?: number | null,
  paidQTY?: number | null,
  pcnt?: number | null,
  totalAmount?: number | null,
  price?: number | null,
};

export type RatingInput = {
  id: string,
  rating: number,
};

export type ModelOrderConditionInput = {
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  paymentID?: ModelStringInput | null,
  paid?: ModelBooleanInput | null,
  serviceFee?: ModelFloatInput | null,
  tips?: ModelFloatInput | null,
  paidAmount?: ModelFloatInput | null,
  refundedAmount?: ModelFloatInput | null,
  remainingAmount?: ModelFloatInput | null,
  tableSimphonyID?: ModelStringInput | null,
  CheckId?: ModelStringInput | null,
  CheckNumber?: ModelStringInput | null,
  CheckSeqNumber?: ModelStringInput | null,
  CheckOpenTime?: ModelStringInput | null,
  CheckCloseTime?: ModelStringInput | null,
  totalOrderAmount?: ModelFloatInput | null,
  SubTotal?: ModelFloatInput | null,
  Tax?: ModelFloatInput | null,
  serviceCharge?: ModelFloatInput | null,
  Due?: ModelFloatInput | null,
  Payment?: ModelFloatInput | null,
  AdditionalPayment?: ModelFloatInput | null,
  TableOpenTime?: ModelStringInput | null,
  usedCash?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
};

export type UpdateOrderInput = {
  id: string,
  accountID?: string | null,
  conceptID?: string | null,
  paymentID?: string | null,
  paid?: boolean | null,
  serviceFee?: number | null,
  tips?: number | null,
  paidAmount?: number | null,
  refundedAmount?: number | null,
  remainingAmount?: number | null,
  tableSimphonyID?: string | null,
  CheckId?: string | null,
  CheckNumber?: string | null,
  CheckSeqNumber?: string | null,
  CheckOpenTime?: string | null,
  CheckCloseTime?: string | null,
  totalOrderAmount?: number | null,
  SubTotal?: number | null,
  Tax?: number | null,
  serviceCharge?: number | null,
  Due?: number | null,
  Payment?: number | null,
  AdditionalPayment?: number | null,
  TableOpenTime?: string | null,
  MenuItems?: Array< MenuItemInput > | null,
  DiscountItems?: Array< DiscountItemInput > | null,
  usedCash?: number | null,
  ratings?: Array< RatingInput | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type CreatePaymentRequestInput = {
  id?: string | null,
  usedCash?: number | null,
  user?: string | null,
  conceptId?: string | null,
  tableSimphonyId?: string | null,
  CheckNum?: string | null,
  CheckSeq?: string | null,
  payment?: PaymentRequestDetailsInput | null,
  percentageArray?: number | null,
  soSocialConversion?: number | null,
  hasDiscounts?: boolean | null,
  discountAmount?: number | null,
  offerAmount?: number | null,
  familyGroupAmount?: number | null,
  isOffer?: boolean | null,
};

export type PaymentRequestDetailsInput = {
  type?: string | null,
  amount?: number | null,
  billAmount?: number | null,
  tips?: number | null,
  serviceCharge?: number | null,
  items?: Array< PaymentRequestItemInput | null > | null,
  userEmail?: string | null,
  paymentMethod?: string | null,
};

export type PaymentRequestItemInput = {
  DetailLink?: string | null,
  qty?: number | null,
};

export type ModelPaymentRequestConditionInput = {
  usedCash?: ModelFloatInput | null,
  user?: ModelStringInput | null,
  conceptId?: ModelStringInput | null,
  tableSimphonyId?: ModelStringInput | null,
  CheckNum?: ModelStringInput | null,
  CheckSeq?: ModelStringInput | null,
  percentageArray?: ModelIntInput | null,
  soSocialConversion?: ModelFloatInput | null,
  hasDiscounts?: ModelBooleanInput | null,
  discountAmount?: ModelFloatInput | null,
  offerAmount?: ModelFloatInput | null,
  familyGroupAmount?: ModelFloatInput | null,
  isOffer?: ModelBooleanInput | null,
  and?: Array< ModelPaymentRequestConditionInput | null > | null,
  or?: Array< ModelPaymentRequestConditionInput | null > | null,
  not?: ModelPaymentRequestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type PaymentRequest = {
  __typename: "PaymentRequest",
  id: string,
  usedCash?: number | null,
  user?: string | null,
  conceptId?: string | null,
  tableSimphonyId?: string | null,
  CheckNum?: string | null,
  CheckSeq?: string | null,
  payment?: PaymentRequestDetails | null,
  percentageArray?: number | null,
  soSocialConversion?: number | null,
  hasDiscounts?: boolean | null,
  discountAmount?: number | null,
  offerAmount?: number | null,
  familyGroupAmount?: number | null,
  isOffer?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type PaymentRequestDetails = {
  __typename: "PaymentRequestDetails",
  type?: string | null,
  amount?: number | null,
  billAmount?: number | null,
  tips?: number | null,
  serviceCharge?: number | null,
  items?:  Array<PaymentRequestItem | null > | null,
  userEmail?: string | null,
  paymentMethod?: string | null,
};

export type PaymentRequestItem = {
  __typename: "PaymentRequestItem",
  DetailLink?: string | null,
  qty?: number | null,
};

export type UpdatePaymentRequestInput = {
  id: string,
  usedCash?: number | null,
  user?: string | null,
  conceptId?: string | null,
  tableSimphonyId?: string | null,
  CheckNum?: string | null,
  CheckSeq?: string | null,
  payment?: PaymentRequestDetailsInput | null,
  percentageArray?: number | null,
  soSocialConversion?: number | null,
  hasDiscounts?: boolean | null,
  discountAmount?: number | null,
  offerAmount?: number | null,
  familyGroupAmount?: number | null,
  isOffer?: boolean | null,
};

export type DeletePaymentRequestInput = {
  id: string,
};

export type CreatePaymentInput = {
  id?: string | null,
  accountID: string,
  conceptID: string,
  orderID: string,
  tableID?: string | null,
  tableName?: string | null,
  channel?: string | null,
  tableOpenTime?: string | null,
  usedCash?: number | null,
  billAmount?: number | null,
  tipAmount?: number | null,
  serviceFees?: number | null,
  order_id?: string | null,
  tracking_id?: string | null,
  bank_ref_no?: string | null,
  order_status?: string | null,
  failure_message?: string | null,
  payment_mode?: string | null,
  card_name?: string | null,
  status_code?: string | null,
  status_message?: string | null,
  currency?: string | null,
  Amount?: string | null,
  billing_name?: string | null,
  billing_address?: string | null,
  billing_city?: string | null,
  billing_state?: string | null,
  billing_zip?: string | null,
  billing_country?: string | null,
  billing_tel?: string | null,
  billing_email?: string | null,
  merchant_param1?: string | null,
  merchant_param2?: string | null,
  merchant_param3?: string | null,
  merchant_param4?: string | null,
  merchant_param5?: string | null,
  merchant_param6?: string | null,
  mer_amount?: string | null,
  eci_value?: string | null,
  card_holder_name?: string | null,
  bank_receipt_no?: string | null,
  bank_qsi_no?: string | null,
  updatedSimphony?: boolean | null,
  updatedDBOrder?: boolean | null,
  errorMessage?: string | null,
  updatedSosocialWallet?: boolean | null,
  retryExecutionArn?: string | null,
  refunded?: boolean | null,
  refundedAmount?: number | null,
  percentageArray?: number | null,
  soSocialConversion?: number | null,
  earned?: boolean | null,
  isRegistered?: boolean | null,
  deleted?: string | null,
  CheckNum?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelPaymentConditionInput = {
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  tableID?: ModelStringInput | null,
  tableName?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  tableOpenTime?: ModelStringInput | null,
  usedCash?: ModelFloatInput | null,
  billAmount?: ModelFloatInput | null,
  tipAmount?: ModelFloatInput | null,
  serviceFees?: ModelFloatInput | null,
  order_id?: ModelStringInput | null,
  tracking_id?: ModelStringInput | null,
  bank_ref_no?: ModelStringInput | null,
  order_status?: ModelStringInput | null,
  failure_message?: ModelStringInput | null,
  payment_mode?: ModelStringInput | null,
  card_name?: ModelStringInput | null,
  status_code?: ModelStringInput | null,
  status_message?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  Amount?: ModelStringInput | null,
  billing_name?: ModelStringInput | null,
  billing_address?: ModelStringInput | null,
  billing_city?: ModelStringInput | null,
  billing_state?: ModelStringInput | null,
  billing_zip?: ModelStringInput | null,
  billing_country?: ModelStringInput | null,
  billing_tel?: ModelStringInput | null,
  billing_email?: ModelStringInput | null,
  merchant_param1?: ModelStringInput | null,
  merchant_param2?: ModelStringInput | null,
  merchant_param3?: ModelStringInput | null,
  merchant_param4?: ModelStringInput | null,
  merchant_param5?: ModelStringInput | null,
  merchant_param6?: ModelStringInput | null,
  mer_amount?: ModelStringInput | null,
  eci_value?: ModelStringInput | null,
  card_holder_name?: ModelStringInput | null,
  bank_receipt_no?: ModelStringInput | null,
  bank_qsi_no?: ModelStringInput | null,
  updatedSimphony?: ModelBooleanInput | null,
  updatedDBOrder?: ModelBooleanInput | null,
  errorMessage?: ModelStringInput | null,
  updatedSosocialWallet?: ModelBooleanInput | null,
  retryExecutionArn?: ModelStringInput | null,
  refunded?: ModelBooleanInput | null,
  refundedAmount?: ModelFloatInput | null,
  percentageArray?: ModelIntInput | null,
  soSocialConversion?: ModelFloatInput | null,
  earned?: ModelBooleanInput | null,
  isRegistered?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  CheckNum?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentConditionInput | null > | null,
  or?: Array< ModelPaymentConditionInput | null > | null,
  not?: ModelPaymentConditionInput | null,
};

export type Payment = {
  __typename: "Payment",
  id: string,
  accountID: string,
  conceptID: string,
  orderID: string,
  tableID?: string | null,
  tableName?: string | null,
  channel?: string | null,
  tableOpenTime?: string | null,
  usedCash?: number | null,
  billAmount?: number | null,
  tipAmount?: number | null,
  serviceFees?: number | null,
  order_id?: string | null,
  tracking_id?: string | null,
  bank_ref_no?: string | null,
  order_status?: string | null,
  failure_message?: string | null,
  payment_mode?: string | null,
  card_name?: string | null,
  status_code?: string | null,
  status_message?: string | null,
  currency?: string | null,
  Amount?: string | null,
  billing_name?: string | null,
  billing_address?: string | null,
  billing_city?: string | null,
  billing_state?: string | null,
  billing_zip?: string | null,
  billing_country?: string | null,
  billing_tel?: string | null,
  billing_email?: string | null,
  merchant_param1?: string | null,
  merchant_param2?: string | null,
  merchant_param3?: string | null,
  merchant_param4?: string | null,
  merchant_param5?: string | null,
  merchant_param6?: string | null,
  mer_amount?: string | null,
  eci_value?: string | null,
  card_holder_name?: string | null,
  bank_receipt_no?: string | null,
  bank_qsi_no?: string | null,
  updatedSimphony?: boolean | null,
  updatedDBOrder?: boolean | null,
  errorMessage?: string | null,
  updatedSosocialWallet?: boolean | null,
  retryExecutionArn?: string | null,
  refunded?: boolean | null,
  refundedAmount?: number | null,
  percentageArray?: number | null,
  soSocialConversion?: number | null,
  earned?: boolean | null,
  isRegistered?: boolean | null,
  deleted?: string | null,
  CheckNum?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePaymentInput = {
  id: string,
  accountID?: string | null,
  conceptID?: string | null,
  orderID?: string | null,
  tableID?: string | null,
  tableName?: string | null,
  channel?: string | null,
  tableOpenTime?: string | null,
  usedCash?: number | null,
  billAmount?: number | null,
  tipAmount?: number | null,
  serviceFees?: number | null,
  order_id?: string | null,
  tracking_id?: string | null,
  bank_ref_no?: string | null,
  order_status?: string | null,
  failure_message?: string | null,
  payment_mode?: string | null,
  card_name?: string | null,
  status_code?: string | null,
  status_message?: string | null,
  currency?: string | null,
  Amount?: string | null,
  billing_name?: string | null,
  billing_address?: string | null,
  billing_city?: string | null,
  billing_state?: string | null,
  billing_zip?: string | null,
  billing_country?: string | null,
  billing_tel?: string | null,
  billing_email?: string | null,
  merchant_param1?: string | null,
  merchant_param2?: string | null,
  merchant_param3?: string | null,
  merchant_param4?: string | null,
  merchant_param5?: string | null,
  merchant_param6?: string | null,
  mer_amount?: string | null,
  eci_value?: string | null,
  card_holder_name?: string | null,
  bank_receipt_no?: string | null,
  bank_qsi_no?: string | null,
  updatedSimphony?: boolean | null,
  updatedDBOrder?: boolean | null,
  errorMessage?: string | null,
  updatedSosocialWallet?: boolean | null,
  retryExecutionArn?: string | null,
  refunded?: boolean | null,
  refundedAmount?: number | null,
  percentageArray?: number | null,
  soSocialConversion?: number | null,
  earned?: boolean | null,
  isRegistered?: boolean | null,
  deleted?: string | null,
  CheckNum?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePaymentInput = {
  id: string,
};

export type CreateReviewInput = {
  id?: string | null,
  accountID: string,
  conceptID: string,
  orderID: string,
  paymentReceipt: string,
  rating: string,
  review?: string | null,
  createdAt?: string | null,
};

export type ModelReviewConditionInput = {
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  paymentReceipt?: ModelStringInput | null,
  rating?: ModelStringInput | null,
  review?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  accountID: string,
  conceptID: string,
  orderID: string,
  paymentReceipt: string,
  rating: string,
  review?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReviewInput = {
  id: string,
  accountID?: string | null,
  conceptID?: string | null,
  orderID?: string | null,
  paymentReceipt?: string | null,
  rating?: string | null,
  review?: string | null,
  createdAt?: string | null,
};

export type DeleteReviewInput = {
  id: string,
};

export type CreateUserIPInput = {
  id?: string | null,
  ip?: string | null,
  date?: string | null,
  times?: number | null,
};

export type ModelUserIPConditionInput = {
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  times?: ModelIntInput | null,
  and?: Array< ModelUserIPConditionInput | null > | null,
  or?: Array< ModelUserIPConditionInput | null > | null,
  not?: ModelUserIPConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UserIP = {
  __typename: "UserIP",
  id: string,
  ip?: string | null,
  date?: string | null,
  times?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserIPInput = {
  id: string,
  ip?: string | null,
  date?: string | null,
  times?: number | null,
};

export type DeleteUserIPInput = {
  id: string,
};

export type CreateMainCategoryInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  precedence: string,
  menuPDF: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
};

export type ModelMainCategoryConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelMainCategoryConditionInput | null > | null,
  or?: Array< ModelMainCategoryConditionInput | null > | null,
  not?: ModelMainCategoryConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type MainCategory = {
  __typename: "MainCategory",
  id: string,
  conceptID: string,
  name: string,
  precedence: string,
  menuPDF: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
};

export type UpdateMainCategoryInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  precedence?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
};

export type DeleteMainCategoryInput = {
  id: string,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  accountLogoId?: ModelIDInput | null,
  accountMainBgId?: ModelIDInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelConceptFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  mainBg?: ModelStringInput | null,
  type?: ModelStringInput | null,
  location?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  longitude?: ModelStringInput | null,
  latitude?: ModelStringInput | null,
  openingHours?: ModelStringInput | null,
  closingHours?: ModelStringInput | null,
  posID?: ModelStringInput | null,
  simphonyIP?: ModelStringInput | null,
  rvcIP?: ModelStringInput | null,
  simphonyEmployeeID?: ModelStringInput | null,
  tenderID?: ModelStringInput | null,
  visaTenderID?: ModelStringInput | null,
  applePayTenderID?: ModelStringInput | null,
  samsungPayTenderID?: ModelStringInput | null,
  tipsNum?: ModelStringInput | null,
  CheckOrderType?: ModelStringInput | null,
  emailTemplateName?: ModelStringInput | null,
  discountObjectNum?: ModelStringInput | null,
  secondaryTender?: ModelStringInput | null,
  EnableLoyalty?: ModelBooleanInput | null,
  emailName?: ModelStringInput | null,
  emailLocation?: ModelStringInput | null,
  emailPhone?: ModelStringInput | null,
  soSocialEarnRatio?: ModelFloatInput | null,
  soSocialRedeemRatio?: ModelFloatInput | null,
  applePayMerchantIdentifier?: ModelStringInput | null,
  isMPGS?: ModelBooleanInput | null,
  retryAfter?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConceptFilterInput | null > | null,
  or?: Array< ModelConceptFilterInput | null > | null,
  not?: ModelConceptFilterInput | null,
};

export type ModelConceptConnection = {
  __typename: "ModelConceptConnection",
  items:  Array<Concept | null >,
  nextToken?: string | null,
};

export type ModelUserConceptsFilterInput = {
  id?: ModelIDInput | null,
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserConceptsFilterInput | null > | null,
  or?: Array< ModelUserConceptsFilterInput | null > | null,
  not?: ModelUserConceptsFilterInput | null,
};

export type ModelUserConceptsConnection = {
  __typename: "ModelUserConceptsConnection",
  items:  Array<UserConcepts | null >,
  nextToken?: string | null,
};

export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  mediaID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
};

export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
};

export type ModelFeatureFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeatureFilterInput | null > | null,
  or?: Array< ModelFeatureFilterInput | null > | null,
  not?: ModelFeatureFilterInput | null,
};

export type ModelFeatureConnection = {
  __typename: "ModelFeatureConnection",
  items:  Array<Feature | null >,
  nextToken?: string | null,
};

export type ModelAdminRoleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminRoleFilterInput | null > | null,
  or?: Array< ModelAdminRoleFilterInput | null > | null,
  not?: ModelAdminRoleFilterInput | null,
};

export type ModelAdminRoleConnection = {
  __typename: "ModelAdminRoleConnection",
  items:  Array<AdminRole | null >,
  nextToken?: string | null,
};

export type ModelAdminGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminGroupFilterInput | null > | null,
  or?: Array< ModelAdminGroupFilterInput | null > | null,
  not?: ModelAdminGroupFilterInput | null,
};

export type ModelAdminGroupConnection = {
  __typename: "ModelAdminGroupConnection",
  items:  Array<AdminGroup | null >,
  nextToken?: string | null,
};

export type ModelMediaFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attachments?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMediaFilterInput | null > | null,
  or?: Array< ModelMediaFilterInput | null > | null,
  not?: ModelMediaFilterInput | null,
};

export type ModelMediaConnection = {
  __typename: "ModelMediaConnection",
  items:  Array<Media | null >,
  nextToken?: string | null,
};

export type ModelTableFilterInput = {
  id?: ModelIDInput | null,
  tableCode?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  lastClosedAt?: ModelStringInput | null,
  lastPaymentStatus?: ModelStringInput | null,
  lastUsedCash?: ModelFloatInput | null,
  and?: Array< ModelTableFilterInput | null > | null,
  or?: Array< ModelTableFilterInput | null > | null,
  not?: ModelTableFilterInput | null,
  tableCurrentOrderId?: ModelIDInput | null,
};

export type ModelTableConnection = {
  __typename: "ModelTableConnection",
  items:  Array<Table | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  paymentID?: ModelStringInput | null,
  paid?: ModelBooleanInput | null,
  serviceFee?: ModelFloatInput | null,
  tips?: ModelFloatInput | null,
  paidAmount?: ModelFloatInput | null,
  refundedAmount?: ModelFloatInput | null,
  remainingAmount?: ModelFloatInput | null,
  tableSimphonyID?: ModelStringInput | null,
  CheckId?: ModelStringInput | null,
  CheckNumber?: ModelStringInput | null,
  CheckSeqNumber?: ModelStringInput | null,
  CheckOpenTime?: ModelStringInput | null,
  CheckCloseTime?: ModelStringInput | null,
  totalOrderAmount?: ModelFloatInput | null,
  SubTotal?: ModelFloatInput | null,
  Tax?: ModelFloatInput | null,
  serviceCharge?: ModelFloatInput | null,
  Due?: ModelFloatInput | null,
  Payment?: ModelFloatInput | null,
  AdditionalPayment?: ModelFloatInput | null,
  TableOpenTime?: ModelStringInput | null,
  usedCash?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type ModelPaymentRequestFilterInput = {
  id?: ModelIDInput | null,
  usedCash?: ModelFloatInput | null,
  user?: ModelStringInput | null,
  conceptId?: ModelStringInput | null,
  tableSimphonyId?: ModelStringInput | null,
  CheckNum?: ModelStringInput | null,
  CheckSeq?: ModelStringInput | null,
  percentageArray?: ModelIntInput | null,
  soSocialConversion?: ModelFloatInput | null,
  hasDiscounts?: ModelBooleanInput | null,
  discountAmount?: ModelFloatInput | null,
  offerAmount?: ModelFloatInput | null,
  familyGroupAmount?: ModelFloatInput | null,
  isOffer?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentRequestFilterInput | null > | null,
  or?: Array< ModelPaymentRequestFilterInput | null > | null,
  not?: ModelPaymentRequestFilterInput | null,
};

export type ModelPaymentRequestConnection = {
  __typename: "ModelPaymentRequestConnection",
  items:  Array<PaymentRequest | null >,
  nextToken?: string | null,
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  tableID?: ModelStringInput | null,
  tableName?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  tableOpenTime?: ModelStringInput | null,
  usedCash?: ModelFloatInput | null,
  billAmount?: ModelFloatInput | null,
  tipAmount?: ModelFloatInput | null,
  serviceFees?: ModelFloatInput | null,
  order_id?: ModelStringInput | null,
  tracking_id?: ModelStringInput | null,
  bank_ref_no?: ModelStringInput | null,
  order_status?: ModelStringInput | null,
  failure_message?: ModelStringInput | null,
  payment_mode?: ModelStringInput | null,
  card_name?: ModelStringInput | null,
  status_code?: ModelStringInput | null,
  status_message?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  Amount?: ModelStringInput | null,
  billing_name?: ModelStringInput | null,
  billing_address?: ModelStringInput | null,
  billing_city?: ModelStringInput | null,
  billing_state?: ModelStringInput | null,
  billing_zip?: ModelStringInput | null,
  billing_country?: ModelStringInput | null,
  billing_tel?: ModelStringInput | null,
  billing_email?: ModelStringInput | null,
  merchant_param1?: ModelStringInput | null,
  merchant_param2?: ModelStringInput | null,
  merchant_param3?: ModelStringInput | null,
  merchant_param4?: ModelStringInput | null,
  merchant_param5?: ModelStringInput | null,
  merchant_param6?: ModelStringInput | null,
  mer_amount?: ModelStringInput | null,
  eci_value?: ModelStringInput | null,
  card_holder_name?: ModelStringInput | null,
  bank_receipt_no?: ModelStringInput | null,
  bank_qsi_no?: ModelStringInput | null,
  updatedSimphony?: ModelBooleanInput | null,
  updatedDBOrder?: ModelBooleanInput | null,
  errorMessage?: ModelStringInput | null,
  updatedSosocialWallet?: ModelBooleanInput | null,
  retryExecutionArn?: ModelStringInput | null,
  refunded?: ModelBooleanInput | null,
  refundedAmount?: ModelFloatInput | null,
  percentageArray?: ModelIntInput | null,
  soSocialConversion?: ModelFloatInput | null,
  earned?: ModelBooleanInput | null,
  isRegistered?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  CheckNum?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentFilterInput | null > | null,
  or?: Array< ModelPaymentFilterInput | null > | null,
  not?: ModelPaymentFilterInput | null,
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection",
  items:  Array<Payment | null >,
  nextToken?: string | null,
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  paymentReceipt?: ModelStringInput | null,
  rating?: ModelStringInput | null,
  review?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
};

export type ModelUserIPFilterInput = {
  id?: ModelIDInput | null,
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  times?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserIPFilterInput | null > | null,
  or?: Array< ModelUserIPFilterInput | null > | null,
  not?: ModelUserIPFilterInput | null,
};

export type ModelUserIPConnection = {
  __typename: "ModelUserIPConnection",
  items:  Array<UserIP | null >,
  nextToken?: string | null,
};

export type ModelMainCategoryFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMainCategoryFilterInput | null > | null,
  or?: Array< ModelMainCategoryFilterInput | null > | null,
  not?: ModelMainCategoryFilterInput | null,
};

export type ModelMainCategoryConnection = {
  __typename: "ModelMainCategoryConnection",
  items:  Array<MainCategory | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  domain?: ModelSubscriptionStringInput | null,
  siteTitle?: ModelSubscriptionStringInput | null,
  tagline?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  siteAddress?: ModelSubscriptionStringInput | null,
  defaultLanguage?: ModelSubscriptionStringInput | null,
  languages?: ModelSubscriptionStringInput | null,
  features?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  socialLinks?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  accountLogoId?: ModelSubscriptionIDInput | null,
  accountMainBgId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionConceptFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  mainBg?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  longitude?: ModelSubscriptionStringInput | null,
  latitude?: ModelSubscriptionStringInput | null,
  openingHours?: ModelSubscriptionStringInput | null,
  closingHours?: ModelSubscriptionStringInput | null,
  posID?: ModelSubscriptionStringInput | null,
  simphonyIP?: ModelSubscriptionStringInput | null,
  rvcIP?: ModelSubscriptionStringInput | null,
  simphonyEmployeeID?: ModelSubscriptionStringInput | null,
  tenderID?: ModelSubscriptionStringInput | null,
  visaTenderID?: ModelSubscriptionStringInput | null,
  applePayTenderID?: ModelSubscriptionStringInput | null,
  samsungPayTenderID?: ModelSubscriptionStringInput | null,
  tipsNum?: ModelSubscriptionStringInput | null,
  CheckOrderType?: ModelSubscriptionStringInput | null,
  emailTemplateName?: ModelSubscriptionStringInput | null,
  discountObjectNum?: ModelSubscriptionStringInput | null,
  secondaryTender?: ModelSubscriptionStringInput | null,
  EnableLoyalty?: ModelSubscriptionBooleanInput | null,
  emailName?: ModelSubscriptionStringInput | null,
  emailLocation?: ModelSubscriptionStringInput | null,
  emailPhone?: ModelSubscriptionStringInput | null,
  soSocialEarnRatio?: ModelSubscriptionFloatInput | null,
  soSocialRedeemRatio?: ModelSubscriptionFloatInput | null,
  applePayMerchantIdentifier?: ModelSubscriptionStringInput | null,
  isMPGS?: ModelSubscriptionBooleanInput | null,
  retryAfter?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConceptFilterInput | null > | null,
  or?: Array< ModelSubscriptionConceptFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUserConceptsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  defaultConcept?: ModelSubscriptionIDInput | null,
  concepts?: ModelSubscriptionIDInput | null,
  conceptsRoles?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
};

export type ModelSubscriptionAttachmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  mediaID?: ModelSubscriptionIDInput | null,
  fileUrl?: ModelSubscriptionStringInput | null,
  filename?: ModelSubscriptionStringInput | null,
  filetype?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
};

export type ModelSubscriptionFeatureFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  parent?: ModelSubscriptionStringInput | null,
  private?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
};

export type ModelSubscriptionAdminRoleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
};

export type ModelSubscriptionAdminGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionIDInput | null,
  users?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
};

export type ModelSubscriptionMediaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionIDInput | null,
  fileUrl?: ModelSubscriptionStringInput | null,
  filename?: ModelSubscriptionStringInput | null,
  filetype?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  attachments?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMediaFilterInput | null > | null,
  or?: Array< ModelSubscriptionMediaFilterInput | null > | null,
};

export type ModelSubscriptionTableFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tableCode?: ModelSubscriptionStringInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  lastClosedAt?: ModelSubscriptionStringInput | null,
  lastPaymentStatus?: ModelSubscriptionStringInput | null,
  lastUsedCash?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionTableFilterInput | null > | null,
  or?: Array< ModelSubscriptionTableFilterInput | null > | null,
  tableCurrentOrderId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  paymentID?: ModelSubscriptionStringInput | null,
  paid?: ModelSubscriptionBooleanInput | null,
  serviceFee?: ModelSubscriptionFloatInput | null,
  tips?: ModelSubscriptionFloatInput | null,
  paidAmount?: ModelSubscriptionFloatInput | null,
  refundedAmount?: ModelSubscriptionFloatInput | null,
  remainingAmount?: ModelSubscriptionFloatInput | null,
  tableSimphonyID?: ModelSubscriptionStringInput | null,
  CheckId?: ModelSubscriptionStringInput | null,
  CheckNumber?: ModelSubscriptionStringInput | null,
  CheckSeqNumber?: ModelSubscriptionStringInput | null,
  CheckOpenTime?: ModelSubscriptionStringInput | null,
  CheckCloseTime?: ModelSubscriptionStringInput | null,
  totalOrderAmount?: ModelSubscriptionFloatInput | null,
  SubTotal?: ModelSubscriptionFloatInput | null,
  Tax?: ModelSubscriptionFloatInput | null,
  serviceCharge?: ModelSubscriptionFloatInput | null,
  Due?: ModelSubscriptionFloatInput | null,
  Payment?: ModelSubscriptionFloatInput | null,
  AdditionalPayment?: ModelSubscriptionFloatInput | null,
  TableOpenTime?: ModelSubscriptionStringInput | null,
  usedCash?: ModelSubscriptionFloatInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
};

export type ModelSubscriptionPaymentRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  usedCash?: ModelSubscriptionFloatInput | null,
  user?: ModelSubscriptionStringInput | null,
  conceptId?: ModelSubscriptionStringInput | null,
  tableSimphonyId?: ModelSubscriptionStringInput | null,
  CheckNum?: ModelSubscriptionStringInput | null,
  CheckSeq?: ModelSubscriptionStringInput | null,
  percentageArray?: ModelSubscriptionIntInput | null,
  soSocialConversion?: ModelSubscriptionFloatInput | null,
  hasDiscounts?: ModelSubscriptionBooleanInput | null,
  discountAmount?: ModelSubscriptionFloatInput | null,
  offerAmount?: ModelSubscriptionFloatInput | null,
  familyGroupAmount?: ModelSubscriptionFloatInput | null,
  isOffer?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentRequestFilterInput | null > | null,
};

export type ModelSubscriptionPaymentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  orderID?: ModelSubscriptionStringInput | null,
  tableID?: ModelSubscriptionStringInput | null,
  tableName?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  tableOpenTime?: ModelSubscriptionStringInput | null,
  usedCash?: ModelSubscriptionFloatInput | null,
  billAmount?: ModelSubscriptionFloatInput | null,
  tipAmount?: ModelSubscriptionFloatInput | null,
  serviceFees?: ModelSubscriptionFloatInput | null,
  order_id?: ModelSubscriptionStringInput | null,
  tracking_id?: ModelSubscriptionStringInput | null,
  bank_ref_no?: ModelSubscriptionStringInput | null,
  order_status?: ModelSubscriptionStringInput | null,
  failure_message?: ModelSubscriptionStringInput | null,
  payment_mode?: ModelSubscriptionStringInput | null,
  card_name?: ModelSubscriptionStringInput | null,
  status_code?: ModelSubscriptionStringInput | null,
  status_message?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  Amount?: ModelSubscriptionStringInput | null,
  billing_name?: ModelSubscriptionStringInput | null,
  billing_address?: ModelSubscriptionStringInput | null,
  billing_city?: ModelSubscriptionStringInput | null,
  billing_state?: ModelSubscriptionStringInput | null,
  billing_zip?: ModelSubscriptionStringInput | null,
  billing_country?: ModelSubscriptionStringInput | null,
  billing_tel?: ModelSubscriptionStringInput | null,
  billing_email?: ModelSubscriptionStringInput | null,
  merchant_param1?: ModelSubscriptionStringInput | null,
  merchant_param2?: ModelSubscriptionStringInput | null,
  merchant_param3?: ModelSubscriptionStringInput | null,
  merchant_param4?: ModelSubscriptionStringInput | null,
  merchant_param5?: ModelSubscriptionStringInput | null,
  merchant_param6?: ModelSubscriptionStringInput | null,
  mer_amount?: ModelSubscriptionStringInput | null,
  eci_value?: ModelSubscriptionStringInput | null,
  card_holder_name?: ModelSubscriptionStringInput | null,
  bank_receipt_no?: ModelSubscriptionStringInput | null,
  bank_qsi_no?: ModelSubscriptionStringInput | null,
  updatedSimphony?: ModelSubscriptionBooleanInput | null,
  updatedDBOrder?: ModelSubscriptionBooleanInput | null,
  errorMessage?: ModelSubscriptionStringInput | null,
  updatedSosocialWallet?: ModelSubscriptionBooleanInput | null,
  retryExecutionArn?: ModelSubscriptionStringInput | null,
  refunded?: ModelSubscriptionBooleanInput | null,
  refundedAmount?: ModelSubscriptionFloatInput | null,
  percentageArray?: ModelSubscriptionIntInput | null,
  soSocialConversion?: ModelSubscriptionFloatInput | null,
  earned?: ModelSubscriptionBooleanInput | null,
  isRegistered?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  CheckNum?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentFilterInput | null > | null,
};

export type ModelSubscriptionReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  orderID?: ModelSubscriptionStringInput | null,
  paymentReceipt?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionStringInput | null,
  review?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionReviewFilterInput | null > | null,
};

export type ModelSubscriptionUserIPFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ip?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  times?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
};

export type ModelSubscriptionMainCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  menuPDF?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMainCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionMainCategoryFilterInput | null > | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    mainBg?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    domain: string,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    accountLogoId?: string | null,
    accountMainBgId?: string | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    mainBg?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    domain: string,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    accountLogoId?: string | null,
    accountMainBgId?: string | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    mainBg?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    domain: string,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    accountLogoId?: string | null,
    accountMainBgId?: string | null,
  } | null,
};

export type CreateConceptMutationVariables = {
  input: CreateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type CreateConceptMutation = {
  createConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    mainBg?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    openingHours?: string | null,
    closingHours?: string | null,
    posID?: string | null,
    simphonyIP?: string | null,
    rvcIP?: string | null,
    simphonyEmployeeID?: string | null,
    tenderID?: string | null,
    visaTenderID?: string | null,
    applePayTenderID?: string | null,
    samsungPayTenderID?: string | null,
    tipsNum?: string | null,
    CheckOrderType?: string | null,
    emailTemplateName?: string | null,
    discountObjectNum?: string | null,
    secondaryTender?: string | null,
    EnableLoyalty?: boolean | null,
    emailName?: string | null,
    emailLocation?: string | null,
    emailPhone?: string | null,
    tips?:  Array< {
      __typename: "Tip",
      id: string,
      amount: number,
      type: string,
      default: boolean,
    } | null > | null,
    serviceFee?:  {
      __typename: "ServiceFee",
      amount: number,
      type: string,
    } | null,
    soSocialEarnRatio?: number | null,
    soSocialRedeemRatio?: number | null,
    paymentMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    refundMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    applePayMerchantIdentifier?: string | null,
    isMPGS?: boolean | null,
    retryAfter?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type UpdateConceptMutationVariables = {
  input: UpdateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type UpdateConceptMutation = {
  updateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    mainBg?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    openingHours?: string | null,
    closingHours?: string | null,
    posID?: string | null,
    simphonyIP?: string | null,
    rvcIP?: string | null,
    simphonyEmployeeID?: string | null,
    tenderID?: string | null,
    visaTenderID?: string | null,
    applePayTenderID?: string | null,
    samsungPayTenderID?: string | null,
    tipsNum?: string | null,
    CheckOrderType?: string | null,
    emailTemplateName?: string | null,
    discountObjectNum?: string | null,
    secondaryTender?: string | null,
    EnableLoyalty?: boolean | null,
    emailName?: string | null,
    emailLocation?: string | null,
    emailPhone?: string | null,
    tips?:  Array< {
      __typename: "Tip",
      id: string,
      amount: number,
      type: string,
      default: boolean,
    } | null > | null,
    serviceFee?:  {
      __typename: "ServiceFee",
      amount: number,
      type: string,
    } | null,
    soSocialEarnRatio?: number | null,
    soSocialRedeemRatio?: number | null,
    paymentMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    refundMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    applePayMerchantIdentifier?: string | null,
    isMPGS?: boolean | null,
    retryAfter?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type DeleteConceptMutationVariables = {
  input: DeleteConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type DeleteConceptMutation = {
  deleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    mainBg?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    openingHours?: string | null,
    closingHours?: string | null,
    posID?: string | null,
    simphonyIP?: string | null,
    rvcIP?: string | null,
    simphonyEmployeeID?: string | null,
    tenderID?: string | null,
    visaTenderID?: string | null,
    applePayTenderID?: string | null,
    samsungPayTenderID?: string | null,
    tipsNum?: string | null,
    CheckOrderType?: string | null,
    emailTemplateName?: string | null,
    discountObjectNum?: string | null,
    secondaryTender?: string | null,
    EnableLoyalty?: boolean | null,
    emailName?: string | null,
    emailLocation?: string | null,
    emailPhone?: string | null,
    tips?:  Array< {
      __typename: "Tip",
      id: string,
      amount: number,
      type: string,
      default: boolean,
    } | null > | null,
    serviceFee?:  {
      __typename: "ServiceFee",
      amount: number,
      type: string,
    } | null,
    soSocialEarnRatio?: number | null,
    soSocialRedeemRatio?: number | null,
    paymentMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    refundMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    applePayMerchantIdentifier?: string | null,
    isMPGS?: boolean | null,
    retryAfter?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type CreateUserConceptsMutationVariables = {
  input: CreateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type CreateUserConceptsMutation = {
  createUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserConceptsMutationVariables = {
  input: UpdateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type UpdateUserConceptsMutation = {
  updateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserConceptsMutationVariables = {
  input: DeleteUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type DeleteUserConceptsMutation = {
  deleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type DeleteAttachmentMutationVariables = {
  input: DeleteAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type DeleteAttachmentMutation = {
  deleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type CreateFeatureMutationVariables = {
  input: CreateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type CreateFeatureMutation = {
  createFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type UpdateFeatureMutationVariables = {
  input: UpdateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type UpdateFeatureMutation = {
  updateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type DeleteFeatureMutationVariables = {
  input: DeleteFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type DeleteFeatureMutation = {
  deleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type CreateAdminRoleMutationVariables = {
  input: CreateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type CreateAdminRoleMutation = {
  createAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type UpdateAdminRoleMutationVariables = {
  input: UpdateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type UpdateAdminRoleMutation = {
  updateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type DeleteAdminRoleMutationVariables = {
  input: DeleteAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type DeleteAdminRoleMutation = {
  deleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type CreateAdminGroupMutationVariables = {
  input: CreateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type CreateAdminGroupMutation = {
  createAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type UpdateAdminGroupMutationVariables = {
  input: UpdateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type UpdateAdminGroupMutation = {
  updateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type DeleteAdminGroupMutationVariables = {
  input: DeleteAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type DeleteAdminGroupMutation = {
  deleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type CreateMediaMutationVariables = {
  input: CreateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type CreateMediaMutation = {
  createMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type UpdateMediaMutationVariables = {
  input: UpdateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type UpdateMediaMutation = {
  updateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type DeleteMediaMutationVariables = {
  input: DeleteMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type DeleteMediaMutation = {
  deleteMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type CreateTableMutationVariables = {
  input: CreateTableInput,
  condition?: ModelTableConditionInput | null,
};

export type CreateTableMutation = {
  createTable?:  {
    __typename: "Table",
    id: string,
    tableCode: string,
    accountID: string,
    conceptID: string,
    simphonyID: string,
    name: string,
    status?: string | null,
    updatedAt?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    currentOrder?:  {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastClosedAt?: string | null,
    lastPaymentStatus?: string | null,
    lastUsedCash?: number | null,
    tableCurrentOrderId?: string | null,
  } | null,
};

export type UpdateTableMutationVariables = {
  input: UpdateTableInput,
  condition?: ModelTableConditionInput | null,
};

export type UpdateTableMutation = {
  updateTable?:  {
    __typename: "Table",
    id: string,
    tableCode: string,
    accountID: string,
    conceptID: string,
    simphonyID: string,
    name: string,
    status?: string | null,
    updatedAt?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    currentOrder?:  {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastClosedAt?: string | null,
    lastPaymentStatus?: string | null,
    lastUsedCash?: number | null,
    tableCurrentOrderId?: string | null,
  } | null,
};

export type DeleteTableMutationVariables = {
  input: DeleteTableInput,
  condition?: ModelTableConditionInput | null,
};

export type DeleteTableMutation = {
  deleteTable?:  {
    __typename: "Table",
    id: string,
    tableCode: string,
    accountID: string,
    conceptID: string,
    simphonyID: string,
    name: string,
    status?: string | null,
    updatedAt?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    currentOrder?:  {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastClosedAt?: string | null,
    lastPaymentStatus?: string | null,
    lastUsedCash?: number | null,
    tableCurrentOrderId?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    accountID: string,
    conceptID: string,
    paymentID?: string | null,
    paid?: boolean | null,
    serviceFee?: number | null,
    tips?: number | null,
    paidAmount?: number | null,
    refundedAmount?: number | null,
    remainingAmount?: number | null,
    tableSimphonyID: string,
    CheckId?: string | null,
    CheckNumber?: string | null,
    CheckSeqNumber?: string | null,
    CheckOpenTime?: string | null,
    CheckCloseTime?: string | null,
    totalOrderAmount?: number | null,
    SubTotal?: number | null,
    Tax?: number | null,
    serviceCharge?: number | null,
    Due?: number | null,
    Payment?: number | null,
    AdditionalPayment?: number | null,
    TableOpenTime?: string | null,
    MenuItems:  Array< {
      __typename: "MenuItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } >,
    DiscountItems?:  Array< {
      __typename: "DiscountItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      pcnt?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } > | null,
    usedCash?: number | null,
    ratings?:  Array< {
      __typename: "Rating",
      id: string,
      rating: number,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    accountID: string,
    conceptID: string,
    paymentID?: string | null,
    paid?: boolean | null,
    serviceFee?: number | null,
    tips?: number | null,
    paidAmount?: number | null,
    refundedAmount?: number | null,
    remainingAmount?: number | null,
    tableSimphonyID: string,
    CheckId?: string | null,
    CheckNumber?: string | null,
    CheckSeqNumber?: string | null,
    CheckOpenTime?: string | null,
    CheckCloseTime?: string | null,
    totalOrderAmount?: number | null,
    SubTotal?: number | null,
    Tax?: number | null,
    serviceCharge?: number | null,
    Due?: number | null,
    Payment?: number | null,
    AdditionalPayment?: number | null,
    TableOpenTime?: string | null,
    MenuItems:  Array< {
      __typename: "MenuItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } >,
    DiscountItems?:  Array< {
      __typename: "DiscountItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      pcnt?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } > | null,
    usedCash?: number | null,
    ratings?:  Array< {
      __typename: "Rating",
      id: string,
      rating: number,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    accountID: string,
    conceptID: string,
    paymentID?: string | null,
    paid?: boolean | null,
    serviceFee?: number | null,
    tips?: number | null,
    paidAmount?: number | null,
    refundedAmount?: number | null,
    remainingAmount?: number | null,
    tableSimphonyID: string,
    CheckId?: string | null,
    CheckNumber?: string | null,
    CheckSeqNumber?: string | null,
    CheckOpenTime?: string | null,
    CheckCloseTime?: string | null,
    totalOrderAmount?: number | null,
    SubTotal?: number | null,
    Tax?: number | null,
    serviceCharge?: number | null,
    Due?: number | null,
    Payment?: number | null,
    AdditionalPayment?: number | null,
    TableOpenTime?: string | null,
    MenuItems:  Array< {
      __typename: "MenuItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } >,
    DiscountItems?:  Array< {
      __typename: "DiscountItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      pcnt?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } > | null,
    usedCash?: number | null,
    ratings?:  Array< {
      __typename: "Rating",
      id: string,
      rating: number,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePaymentRequestMutationVariables = {
  input: CreatePaymentRequestInput,
  condition?: ModelPaymentRequestConditionInput | null,
};

export type CreatePaymentRequestMutation = {
  createPaymentRequest?:  {
    __typename: "PaymentRequest",
    id: string,
    usedCash?: number | null,
    user?: string | null,
    conceptId?: string | null,
    tableSimphonyId?: string | null,
    CheckNum?: string | null,
    CheckSeq?: string | null,
    payment?:  {
      __typename: "PaymentRequestDetails",
      type?: string | null,
      amount?: number | null,
      billAmount?: number | null,
      tips?: number | null,
      serviceCharge?: number | null,
      items?:  Array< {
        __typename: "PaymentRequestItem",
        DetailLink?: string | null,
        qty?: number | null,
      } | null > | null,
      userEmail?: string | null,
      paymentMethod?: string | null,
    } | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    hasDiscounts?: boolean | null,
    discountAmount?: number | null,
    offerAmount?: number | null,
    familyGroupAmount?: number | null,
    isOffer?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePaymentRequestMutationVariables = {
  input: UpdatePaymentRequestInput,
  condition?: ModelPaymentRequestConditionInput | null,
};

export type UpdatePaymentRequestMutation = {
  updatePaymentRequest?:  {
    __typename: "PaymentRequest",
    id: string,
    usedCash?: number | null,
    user?: string | null,
    conceptId?: string | null,
    tableSimphonyId?: string | null,
    CheckNum?: string | null,
    CheckSeq?: string | null,
    payment?:  {
      __typename: "PaymentRequestDetails",
      type?: string | null,
      amount?: number | null,
      billAmount?: number | null,
      tips?: number | null,
      serviceCharge?: number | null,
      items?:  Array< {
        __typename: "PaymentRequestItem",
        DetailLink?: string | null,
        qty?: number | null,
      } | null > | null,
      userEmail?: string | null,
      paymentMethod?: string | null,
    } | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    hasDiscounts?: boolean | null,
    discountAmount?: number | null,
    offerAmount?: number | null,
    familyGroupAmount?: number | null,
    isOffer?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePaymentRequestMutationVariables = {
  input: DeletePaymentRequestInput,
  condition?: ModelPaymentRequestConditionInput | null,
};

export type DeletePaymentRequestMutation = {
  deletePaymentRequest?:  {
    __typename: "PaymentRequest",
    id: string,
    usedCash?: number | null,
    user?: string | null,
    conceptId?: string | null,
    tableSimphonyId?: string | null,
    CheckNum?: string | null,
    CheckSeq?: string | null,
    payment?:  {
      __typename: "PaymentRequestDetails",
      type?: string | null,
      amount?: number | null,
      billAmount?: number | null,
      tips?: number | null,
      serviceCharge?: number | null,
      items?:  Array< {
        __typename: "PaymentRequestItem",
        DetailLink?: string | null,
        qty?: number | null,
      } | null > | null,
      userEmail?: string | null,
      paymentMethod?: string | null,
    } | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    hasDiscounts?: boolean | null,
    discountAmount?: number | null,
    offerAmount?: number | null,
    familyGroupAmount?: number | null,
    isOffer?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type CreatePaymentMutation = {
  createPayment?:  {
    __typename: "Payment",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    tableID?: string | null,
    tableName?: string | null,
    channel?: string | null,
    tableOpenTime?: string | null,
    usedCash?: number | null,
    billAmount?: number | null,
    tipAmount?: number | null,
    serviceFees?: number | null,
    order_id?: string | null,
    tracking_id?: string | null,
    bank_ref_no?: string | null,
    order_status?: string | null,
    failure_message?: string | null,
    payment_mode?: string | null,
    card_name?: string | null,
    status_code?: string | null,
    status_message?: string | null,
    currency?: string | null,
    Amount?: string | null,
    billing_name?: string | null,
    billing_address?: string | null,
    billing_city?: string | null,
    billing_state?: string | null,
    billing_zip?: string | null,
    billing_country?: string | null,
    billing_tel?: string | null,
    billing_email?: string | null,
    merchant_param1?: string | null,
    merchant_param2?: string | null,
    merchant_param3?: string | null,
    merchant_param4?: string | null,
    merchant_param5?: string | null,
    merchant_param6?: string | null,
    mer_amount?: string | null,
    eci_value?: string | null,
    card_holder_name?: string | null,
    bank_receipt_no?: string | null,
    bank_qsi_no?: string | null,
    updatedSimphony?: boolean | null,
    updatedDBOrder?: boolean | null,
    errorMessage?: string | null,
    updatedSosocialWallet?: boolean | null,
    retryExecutionArn?: string | null,
    refunded?: boolean | null,
    refundedAmount?: number | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    earned?: boolean | null,
    isRegistered?: boolean | null,
    deleted?: string | null,
    CheckNum?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment?:  {
    __typename: "Payment",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    tableID?: string | null,
    tableName?: string | null,
    channel?: string | null,
    tableOpenTime?: string | null,
    usedCash?: number | null,
    billAmount?: number | null,
    tipAmount?: number | null,
    serviceFees?: number | null,
    order_id?: string | null,
    tracking_id?: string | null,
    bank_ref_no?: string | null,
    order_status?: string | null,
    failure_message?: string | null,
    payment_mode?: string | null,
    card_name?: string | null,
    status_code?: string | null,
    status_message?: string | null,
    currency?: string | null,
    Amount?: string | null,
    billing_name?: string | null,
    billing_address?: string | null,
    billing_city?: string | null,
    billing_state?: string | null,
    billing_zip?: string | null,
    billing_country?: string | null,
    billing_tel?: string | null,
    billing_email?: string | null,
    merchant_param1?: string | null,
    merchant_param2?: string | null,
    merchant_param3?: string | null,
    merchant_param4?: string | null,
    merchant_param5?: string | null,
    merchant_param6?: string | null,
    mer_amount?: string | null,
    eci_value?: string | null,
    card_holder_name?: string | null,
    bank_receipt_no?: string | null,
    bank_qsi_no?: string | null,
    updatedSimphony?: boolean | null,
    updatedDBOrder?: boolean | null,
    errorMessage?: string | null,
    updatedSosocialWallet?: boolean | null,
    retryExecutionArn?: string | null,
    refunded?: boolean | null,
    refundedAmount?: number | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    earned?: boolean | null,
    isRegistered?: boolean | null,
    deleted?: string | null,
    CheckNum?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type DeletePaymentMutation = {
  deletePayment?:  {
    __typename: "Payment",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    tableID?: string | null,
    tableName?: string | null,
    channel?: string | null,
    tableOpenTime?: string | null,
    usedCash?: number | null,
    billAmount?: number | null,
    tipAmount?: number | null,
    serviceFees?: number | null,
    order_id?: string | null,
    tracking_id?: string | null,
    bank_ref_no?: string | null,
    order_status?: string | null,
    failure_message?: string | null,
    payment_mode?: string | null,
    card_name?: string | null,
    status_code?: string | null,
    status_message?: string | null,
    currency?: string | null,
    Amount?: string | null,
    billing_name?: string | null,
    billing_address?: string | null,
    billing_city?: string | null,
    billing_state?: string | null,
    billing_zip?: string | null,
    billing_country?: string | null,
    billing_tel?: string | null,
    billing_email?: string | null,
    merchant_param1?: string | null,
    merchant_param2?: string | null,
    merchant_param3?: string | null,
    merchant_param4?: string | null,
    merchant_param5?: string | null,
    merchant_param6?: string | null,
    mer_amount?: string | null,
    eci_value?: string | null,
    card_holder_name?: string | null,
    bank_receipt_no?: string | null,
    bank_qsi_no?: string | null,
    updatedSimphony?: boolean | null,
    updatedDBOrder?: boolean | null,
    errorMessage?: string | null,
    updatedSosocialWallet?: boolean | null,
    retryExecutionArn?: string | null,
    refunded?: boolean | null,
    refundedAmount?: number | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    earned?: boolean | null,
    isRegistered?: boolean | null,
    deleted?: string | null,
    CheckNum?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    paymentReceipt: string,
    rating: string,
    review?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    paymentReceipt: string,
    rating: string,
    review?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    paymentReceipt: string,
    rating: string,
    review?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserIPMutationVariables = {
  input: CreateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type CreateUserIPMutation = {
  createUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserIPMutationVariables = {
  input: UpdateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type UpdateUserIPMutation = {
  updateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserIPMutationVariables = {
  input: DeleteUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type DeleteUserIPMutation = {
  deleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMainCategoryMutationVariables = {
  input: CreateMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type CreateMainCategoryMutation = {
  createMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    menuPDF: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type UpdateMainCategoryMutationVariables = {
  input: UpdateMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type UpdateMainCategoryMutation = {
  updateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    menuPDF: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type DeleteMainCategoryMutationVariables = {
  input: DeleteMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type DeleteMainCategoryMutation = {
  deleteMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    menuPDF: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    mainBg?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    domain: string,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    accountLogoId?: string | null,
    accountMainBgId?: string | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
      } | null,
      mainBg?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
      } | null,
      domain: string,
      siteTitle: string,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages: Array< string | null >,
      features: Array< string | null >,
      status: string,
      socialLinks?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      accountLogoId?: string | null,
      accountMainBgId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConceptQueryVariables = {
  id: string,
};

export type GetConceptQuery = {
  getConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    mainBg?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    openingHours?: string | null,
    closingHours?: string | null,
    posID?: string | null,
    simphonyIP?: string | null,
    rvcIP?: string | null,
    simphonyEmployeeID?: string | null,
    tenderID?: string | null,
    visaTenderID?: string | null,
    applePayTenderID?: string | null,
    samsungPayTenderID?: string | null,
    tipsNum?: string | null,
    CheckOrderType?: string | null,
    emailTemplateName?: string | null,
    discountObjectNum?: string | null,
    secondaryTender?: string | null,
    EnableLoyalty?: boolean | null,
    emailName?: string | null,
    emailLocation?: string | null,
    emailPhone?: string | null,
    tips?:  Array< {
      __typename: "Tip",
      id: string,
      amount: number,
      type: string,
      default: boolean,
    } | null > | null,
    serviceFee?:  {
      __typename: "ServiceFee",
      amount: number,
      type: string,
    } | null,
    soSocialEarnRatio?: number | null,
    soSocialRedeemRatio?: number | null,
    paymentMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    refundMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    applePayMerchantIdentifier?: string | null,
    isMPGS?: boolean | null,
    retryAfter?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type ListConceptsQueryVariables = {
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConceptsQuery = {
  listConcepts?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      description?: string | null,
      logo?: string | null,
      mainBg?: string | null,
      type?: string | null,
      location?: string | null,
      precedence?: number | null,
      longitude?: string | null,
      latitude?: string | null,
      openingHours?: string | null,
      closingHours?: string | null,
      posID?: string | null,
      simphonyIP?: string | null,
      rvcIP?: string | null,
      simphonyEmployeeID?: string | null,
      tenderID?: string | null,
      visaTenderID?: string | null,
      applePayTenderID?: string | null,
      samsungPayTenderID?: string | null,
      tipsNum?: string | null,
      CheckOrderType?: string | null,
      emailTemplateName?: string | null,
      discountObjectNum?: string | null,
      secondaryTender?: string | null,
      EnableLoyalty?: boolean | null,
      emailName?: string | null,
      emailLocation?: string | null,
      emailPhone?: string | null,
      tips?:  Array< {
        __typename: "Tip",
        id: string,
        amount: number,
        type: string,
        default: boolean,
      } | null > | null,
      serviceFee?:  {
        __typename: "ServiceFee",
        amount: number,
        type: string,
      } | null,
      soSocialEarnRatio?: number | null,
      soSocialRedeemRatio?: number | null,
      paymentMerchants?:  Array< {
        __typename: "paymentMerchant",
        channel?: string | null,
        merchant_id?: string | null,
        access_code?: string | null,
        enc_key?: string | null,
        url?: string | null,
        env?: string | null,
      } | null > | null,
      refundMerchants?:  Array< {
        __typename: "paymentMerchant",
        channel?: string | null,
        merchant_id?: string | null,
        access_code?: string | null,
        enc_key?: string | null,
        url?: string | null,
        env?: string | null,
      } | null > | null,
      applePayMerchantIdentifier?: string | null,
      isMPGS?: boolean | null,
      retryAfter?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserConceptsQueryVariables = {
  id: string,
};

export type GetUserConceptsQuery = {
  getUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserConceptsQueryVariables = {
  filter?: ModelUserConceptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserConceptsQuery = {
  listUserConcepts?:  {
    __typename: "ModelUserConceptsConnection",
    items:  Array< {
      __typename: "UserConcepts",
      id: string,
      defaultConcept?: string | null,
      concepts?: Array< string | null > | null,
      conceptsRoles?: Array< string | null > | null,
      deleted?: string | null,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeatureQueryVariables = {
  id: string,
};

export type GetFeatureQuery = {
  getFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type ListFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturesQuery = {
  listFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon?: string | null,
      slug: string,
      precedence: string,
      parent?: string | null,
      private?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdminRoleQueryVariables = {
  id: string,
};

export type GetAdminRoleQuery = {
  getAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type ListAdminRolesQueryVariables = {
  filter?: ModelAdminRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminRolesQuery = {
  listAdminRoles?:  {
    __typename: "ModelAdminRoleConnection",
    items:  Array< {
      __typename: "AdminRole",
      id: string,
      name?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdminGroupQueryVariables = {
  id: string,
};

export type GetAdminGroupQuery = {
  getAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type ListAdminGroupsQueryVariables = {
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminGroupsQuery = {
  listAdminGroups?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMediaQueryVariables = {
  id: string,
};

export type GetMediaQuery = {
  getMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type ListMediaQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaQuery = {
  listMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      accountID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTableQueryVariables = {
  id: string,
};

export type GetTableQuery = {
  getTable?:  {
    __typename: "Table",
    id: string,
    tableCode: string,
    accountID: string,
    conceptID: string,
    simphonyID: string,
    name: string,
    status?: string | null,
    updatedAt?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    currentOrder?:  {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastClosedAt?: string | null,
    lastPaymentStatus?: string | null,
    lastUsedCash?: number | null,
    tableCurrentOrderId?: string | null,
  } | null,
};

export type ListTablesQueryVariables = {
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTablesQuery = {
  listTables?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      tableCode: string,
      accountID: string,
      conceptID: string,
      simphonyID: string,
      name: string,
      status?: string | null,
      updatedAt?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      currentOrder?:  {
        __typename: "Order",
        id: string,
        accountID: string,
        conceptID: string,
        paymentID?: string | null,
        paid?: boolean | null,
        serviceFee?: number | null,
        tips?: number | null,
        paidAmount?: number | null,
        refundedAmount?: number | null,
        remainingAmount?: number | null,
        tableSimphonyID: string,
        CheckId?: string | null,
        CheckNumber?: string | null,
        CheckSeqNumber?: string | null,
        CheckOpenTime?: string | null,
        CheckCloseTime?: string | null,
        totalOrderAmount?: number | null,
        SubTotal?: number | null,
        Tax?: number | null,
        serviceCharge?: number | null,
        Due?: number | null,
        Payment?: number | null,
        AdditionalPayment?: number | null,
        TableOpenTime?: string | null,
        usedCash?: number | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      lastClosedAt?: string | null,
      lastPaymentStatus?: string | null,
      lastUsedCash?: number | null,
      tableCurrentOrderId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TablesByConceptQueryVariables = {
  conceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TablesByConceptQuery = {
  tablesByConcept?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      tableCode: string,
      accountID: string,
      conceptID: string,
      simphonyID: string,
      name: string,
      status?: string | null,
      updatedAt?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      currentOrder?:  {
        __typename: "Order",
        id: string,
        accountID: string,
        conceptID: string,
        paymentID?: string | null,
        paid?: boolean | null,
        serviceFee?: number | null,
        tips?: number | null,
        paidAmount?: number | null,
        refundedAmount?: number | null,
        remainingAmount?: number | null,
        tableSimphonyID: string,
        CheckId?: string | null,
        CheckNumber?: string | null,
        CheckSeqNumber?: string | null,
        CheckOpenTime?: string | null,
        CheckCloseTime?: string | null,
        totalOrderAmount?: number | null,
        SubTotal?: number | null,
        Tax?: number | null,
        serviceCharge?: number | null,
        Due?: number | null,
        Payment?: number | null,
        AdditionalPayment?: number | null,
        TableOpenTime?: string | null,
        usedCash?: number | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      lastClosedAt?: string | null,
      lastPaymentStatus?: string | null,
      lastUsedCash?: number | null,
      tableCurrentOrderId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TablesByConceptSortedByUpdatedAtQueryVariables = {
  conceptID: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TablesByConceptSortedByUpdatedAtQuery = {
  tablesByConceptSortedByUpdatedAt?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      tableCode: string,
      accountID: string,
      conceptID: string,
      simphonyID: string,
      name: string,
      status?: string | null,
      updatedAt?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      currentOrder?:  {
        __typename: "Order",
        id: string,
        accountID: string,
        conceptID: string,
        paymentID?: string | null,
        paid?: boolean | null,
        serviceFee?: number | null,
        tips?: number | null,
        paidAmount?: number | null,
        refundedAmount?: number | null,
        remainingAmount?: number | null,
        tableSimphonyID: string,
        CheckId?: string | null,
        CheckNumber?: string | null,
        CheckSeqNumber?: string | null,
        CheckOpenTime?: string | null,
        CheckCloseTime?: string | null,
        totalOrderAmount?: number | null,
        SubTotal?: number | null,
        Tax?: number | null,
        serviceCharge?: number | null,
        Due?: number | null,
        Payment?: number | null,
        AdditionalPayment?: number | null,
        TableOpenTime?: string | null,
        usedCash?: number | null,
        deleted?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      lastClosedAt?: string | null,
      lastPaymentStatus?: string | null,
      lastUsedCash?: number | null,
      tableCurrentOrderId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    accountID: string,
    conceptID: string,
    paymentID?: string | null,
    paid?: boolean | null,
    serviceFee?: number | null,
    tips?: number | null,
    paidAmount?: number | null,
    refundedAmount?: number | null,
    remainingAmount?: number | null,
    tableSimphonyID: string,
    CheckId?: string | null,
    CheckNumber?: string | null,
    CheckSeqNumber?: string | null,
    CheckOpenTime?: string | null,
    CheckCloseTime?: string | null,
    totalOrderAmount?: number | null,
    SubTotal?: number | null,
    Tax?: number | null,
    serviceCharge?: number | null,
    Due?: number | null,
    Payment?: number | null,
    AdditionalPayment?: number | null,
    TableOpenTime?: string | null,
    MenuItems:  Array< {
      __typename: "MenuItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } >,
    DiscountItems?:  Array< {
      __typename: "DiscountItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      pcnt?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } > | null,
    usedCash?: number | null,
    ratings?:  Array< {
      __typename: "Rating",
      id: string,
      rating: number,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderByConceptQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByConceptQuery = {
  orderByConcept?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrdersByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersByDeletedQuery = {
  listOrdersByDeleted?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentRequestQueryVariables = {
  id: string,
};

export type GetPaymentRequestQuery = {
  getPaymentRequest?:  {
    __typename: "PaymentRequest",
    id: string,
    usedCash?: number | null,
    user?: string | null,
    conceptId?: string | null,
    tableSimphonyId?: string | null,
    CheckNum?: string | null,
    CheckSeq?: string | null,
    payment?:  {
      __typename: "PaymentRequestDetails",
      type?: string | null,
      amount?: number | null,
      billAmount?: number | null,
      tips?: number | null,
      serviceCharge?: number | null,
      items?:  Array< {
        __typename: "PaymentRequestItem",
        DetailLink?: string | null,
        qty?: number | null,
      } | null > | null,
      userEmail?: string | null,
      paymentMethod?: string | null,
    } | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    hasDiscounts?: boolean | null,
    discountAmount?: number | null,
    offerAmount?: number | null,
    familyGroupAmount?: number | null,
    isOffer?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPaymentRequestsQueryVariables = {
  filter?: ModelPaymentRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentRequestsQuery = {
  listPaymentRequests?:  {
    __typename: "ModelPaymentRequestConnection",
    items:  Array< {
      __typename: "PaymentRequest",
      id: string,
      usedCash?: number | null,
      user?: string | null,
      conceptId?: string | null,
      tableSimphonyId?: string | null,
      CheckNum?: string | null,
      CheckSeq?: string | null,
      payment?:  {
        __typename: "PaymentRequestDetails",
        type?: string | null,
        amount?: number | null,
        billAmount?: number | null,
        tips?: number | null,
        serviceCharge?: number | null,
        userEmail?: string | null,
        paymentMethod?: string | null,
      } | null,
      percentageArray?: number | null,
      soSocialConversion?: number | null,
      hasDiscounts?: boolean | null,
      discountAmount?: number | null,
      offerAmount?: number | null,
      familyGroupAmount?: number | null,
      isOffer?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment?:  {
    __typename: "Payment",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    tableID?: string | null,
    tableName?: string | null,
    channel?: string | null,
    tableOpenTime?: string | null,
    usedCash?: number | null,
    billAmount?: number | null,
    tipAmount?: number | null,
    serviceFees?: number | null,
    order_id?: string | null,
    tracking_id?: string | null,
    bank_ref_no?: string | null,
    order_status?: string | null,
    failure_message?: string | null,
    payment_mode?: string | null,
    card_name?: string | null,
    status_code?: string | null,
    status_message?: string | null,
    currency?: string | null,
    Amount?: string | null,
    billing_name?: string | null,
    billing_address?: string | null,
    billing_city?: string | null,
    billing_state?: string | null,
    billing_zip?: string | null,
    billing_country?: string | null,
    billing_tel?: string | null,
    billing_email?: string | null,
    merchant_param1?: string | null,
    merchant_param2?: string | null,
    merchant_param3?: string | null,
    merchant_param4?: string | null,
    merchant_param5?: string | null,
    merchant_param6?: string | null,
    mer_amount?: string | null,
    eci_value?: string | null,
    card_holder_name?: string | null,
    bank_receipt_no?: string | null,
    bank_qsi_no?: string | null,
    updatedSimphony?: boolean | null,
    updatedDBOrder?: boolean | null,
    errorMessage?: string | null,
    updatedSosocialWallet?: boolean | null,
    retryExecutionArn?: string | null,
    refunded?: boolean | null,
    refundedAmount?: number | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    earned?: boolean | null,
    isRegistered?: boolean | null,
    deleted?: string | null,
    CheckNum?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsQuery = {
  listPayments?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      accountID: string,
      conceptID: string,
      orderID: string,
      tableID?: string | null,
      tableName?: string | null,
      channel?: string | null,
      tableOpenTime?: string | null,
      usedCash?: number | null,
      billAmount?: number | null,
      tipAmount?: number | null,
      serviceFees?: number | null,
      order_id?: string | null,
      tracking_id?: string | null,
      bank_ref_no?: string | null,
      order_status?: string | null,
      failure_message?: string | null,
      payment_mode?: string | null,
      card_name?: string | null,
      status_code?: string | null,
      status_message?: string | null,
      currency?: string | null,
      Amount?: string | null,
      billing_name?: string | null,
      billing_address?: string | null,
      billing_city?: string | null,
      billing_state?: string | null,
      billing_zip?: string | null,
      billing_country?: string | null,
      billing_tel?: string | null,
      billing_email?: string | null,
      merchant_param1?: string | null,
      merchant_param2?: string | null,
      merchant_param3?: string | null,
      merchant_param4?: string | null,
      merchant_param5?: string | null,
      merchant_param6?: string | null,
      mer_amount?: string | null,
      eci_value?: string | null,
      card_holder_name?: string | null,
      bank_receipt_no?: string | null,
      bank_qsi_no?: string | null,
      updatedSimphony?: boolean | null,
      updatedDBOrder?: boolean | null,
      errorMessage?: string | null,
      updatedSosocialWallet?: boolean | null,
      retryExecutionArn?: string | null,
      refunded?: boolean | null,
      refundedAmount?: number | null,
      percentageArray?: number | null,
      soSocialConversion?: number | null,
      earned?: boolean | null,
      isRegistered?: boolean | null,
      deleted?: string | null,
      CheckNum?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentByConceptQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentByConceptQuery = {
  paymentByConcept?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      accountID: string,
      conceptID: string,
      orderID: string,
      tableID?: string | null,
      tableName?: string | null,
      channel?: string | null,
      tableOpenTime?: string | null,
      usedCash?: number | null,
      billAmount?: number | null,
      tipAmount?: number | null,
      serviceFees?: number | null,
      order_id?: string | null,
      tracking_id?: string | null,
      bank_ref_no?: string | null,
      order_status?: string | null,
      failure_message?: string | null,
      payment_mode?: string | null,
      card_name?: string | null,
      status_code?: string | null,
      status_message?: string | null,
      currency?: string | null,
      Amount?: string | null,
      billing_name?: string | null,
      billing_address?: string | null,
      billing_city?: string | null,
      billing_state?: string | null,
      billing_zip?: string | null,
      billing_country?: string | null,
      billing_tel?: string | null,
      billing_email?: string | null,
      merchant_param1?: string | null,
      merchant_param2?: string | null,
      merchant_param3?: string | null,
      merchant_param4?: string | null,
      merchant_param5?: string | null,
      merchant_param6?: string | null,
      mer_amount?: string | null,
      eci_value?: string | null,
      card_holder_name?: string | null,
      bank_receipt_no?: string | null,
      bank_qsi_no?: string | null,
      updatedSimphony?: boolean | null,
      updatedDBOrder?: boolean | null,
      errorMessage?: string | null,
      updatedSosocialWallet?: boolean | null,
      retryExecutionArn?: string | null,
      refunded?: boolean | null,
      refundedAmount?: number | null,
      percentageArray?: number | null,
      soSocialConversion?: number | null,
      earned?: boolean | null,
      isRegistered?: boolean | null,
      deleted?: string | null,
      CheckNum?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderPaymentsQueryVariables = {
  orderID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderPaymentsQuery = {
  orderPayments?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      accountID: string,
      conceptID: string,
      orderID: string,
      tableID?: string | null,
      tableName?: string | null,
      channel?: string | null,
      tableOpenTime?: string | null,
      usedCash?: number | null,
      billAmount?: number | null,
      tipAmount?: number | null,
      serviceFees?: number | null,
      order_id?: string | null,
      tracking_id?: string | null,
      bank_ref_no?: string | null,
      order_status?: string | null,
      failure_message?: string | null,
      payment_mode?: string | null,
      card_name?: string | null,
      status_code?: string | null,
      status_message?: string | null,
      currency?: string | null,
      Amount?: string | null,
      billing_name?: string | null,
      billing_address?: string | null,
      billing_city?: string | null,
      billing_state?: string | null,
      billing_zip?: string | null,
      billing_country?: string | null,
      billing_tel?: string | null,
      billing_email?: string | null,
      merchant_param1?: string | null,
      merchant_param2?: string | null,
      merchant_param3?: string | null,
      merchant_param4?: string | null,
      merchant_param5?: string | null,
      merchant_param6?: string | null,
      mer_amount?: string | null,
      eci_value?: string | null,
      card_holder_name?: string | null,
      bank_receipt_no?: string | null,
      bank_qsi_no?: string | null,
      updatedSimphony?: boolean | null,
      updatedDBOrder?: boolean | null,
      errorMessage?: string | null,
      updatedSosocialWallet?: boolean | null,
      retryExecutionArn?: string | null,
      refunded?: boolean | null,
      refundedAmount?: number | null,
      percentageArray?: number | null,
      soSocialConversion?: number | null,
      earned?: boolean | null,
      isRegistered?: boolean | null,
      deleted?: string | null,
      CheckNum?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    paymentReceipt: string,
    rating: string,
    review?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      accountID: string,
      conceptID: string,
      orderID: string,
      paymentReceipt: string,
      rating: string,
      review?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewByConceptQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewByConceptQuery = {
  reviewByConcept?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      accountID: string,
      conceptID: string,
      orderID: string,
      paymentReceipt: string,
      rating: string,
      review?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewByConceptSortedByRatingQueryVariables = {
  conceptID: string,
  rating?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewByConceptSortedByRatingQuery = {
  reviewByConceptSortedByRating?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      accountID: string,
      conceptID: string,
      orderID: string,
      paymentReceipt: string,
      rating: string,
      review?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewsByOrderQueryVariables = {
  orderID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByOrderQuery = {
  reviewsByOrder?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      accountID: string,
      conceptID: string,
      orderID: string,
      paymentReceipt: string,
      rating: string,
      review?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserIPQueryVariables = {
  id: string,
};

export type GetUserIPQuery = {
  getUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserIPSQueryVariables = {
  filter?: ModelUserIPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserIPSQuery = {
  listUserIPS?:  {
    __typename: "ModelUserIPConnection",
    items:  Array< {
      __typename: "UserIP",
      id: string,
      ip?: string | null,
      date?: string | null,
      times?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMainCategoryQueryVariables = {
  id: string,
};

export type GetMainCategoryQuery = {
  getMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    menuPDF: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type ListMainCategoriesQueryVariables = {
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMainCategoriesQuery = {
  listMainCategories?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      menuPDF: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MainCategoryByConceptQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MainCategoryByConceptQuery = {
  MainCategoryByConcept?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      menuPDF: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    mainBg?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    domain: string,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    accountLogoId?: string | null,
    accountMainBgId?: string | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    mainBg?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    domain: string,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    accountLogoId?: string | null,
    accountMainBgId?: string | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    mainBg?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
    } | null,
    domain: string,
    siteTitle: string,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    accountLogoId?: string | null,
    accountMainBgId?: string | null,
  } | null,
};

export type OnCreateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnCreateConceptSubscription = {
  onCreateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    mainBg?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    openingHours?: string | null,
    closingHours?: string | null,
    posID?: string | null,
    simphonyIP?: string | null,
    rvcIP?: string | null,
    simphonyEmployeeID?: string | null,
    tenderID?: string | null,
    visaTenderID?: string | null,
    applePayTenderID?: string | null,
    samsungPayTenderID?: string | null,
    tipsNum?: string | null,
    CheckOrderType?: string | null,
    emailTemplateName?: string | null,
    discountObjectNum?: string | null,
    secondaryTender?: string | null,
    EnableLoyalty?: boolean | null,
    emailName?: string | null,
    emailLocation?: string | null,
    emailPhone?: string | null,
    tips?:  Array< {
      __typename: "Tip",
      id: string,
      amount: number,
      type: string,
      default: boolean,
    } | null > | null,
    serviceFee?:  {
      __typename: "ServiceFee",
      amount: number,
      type: string,
    } | null,
    soSocialEarnRatio?: number | null,
    soSocialRedeemRatio?: number | null,
    paymentMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    refundMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    applePayMerchantIdentifier?: string | null,
    isMPGS?: boolean | null,
    retryAfter?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnUpdateConceptSubscription = {
  onUpdateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    mainBg?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    openingHours?: string | null,
    closingHours?: string | null,
    posID?: string | null,
    simphonyIP?: string | null,
    rvcIP?: string | null,
    simphonyEmployeeID?: string | null,
    tenderID?: string | null,
    visaTenderID?: string | null,
    applePayTenderID?: string | null,
    samsungPayTenderID?: string | null,
    tipsNum?: string | null,
    CheckOrderType?: string | null,
    emailTemplateName?: string | null,
    discountObjectNum?: string | null,
    secondaryTender?: string | null,
    EnableLoyalty?: boolean | null,
    emailName?: string | null,
    emailLocation?: string | null,
    emailPhone?: string | null,
    tips?:  Array< {
      __typename: "Tip",
      id: string,
      amount: number,
      type: string,
      default: boolean,
    } | null > | null,
    serviceFee?:  {
      __typename: "ServiceFee",
      amount: number,
      type: string,
    } | null,
    soSocialEarnRatio?: number | null,
    soSocialRedeemRatio?: number | null,
    paymentMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    refundMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    applePayMerchantIdentifier?: string | null,
    isMPGS?: boolean | null,
    retryAfter?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnDeleteConceptSubscription = {
  onDeleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    mainBg?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    openingHours?: string | null,
    closingHours?: string | null,
    posID?: string | null,
    simphonyIP?: string | null,
    rvcIP?: string | null,
    simphonyEmployeeID?: string | null,
    tenderID?: string | null,
    visaTenderID?: string | null,
    applePayTenderID?: string | null,
    samsungPayTenderID?: string | null,
    tipsNum?: string | null,
    CheckOrderType?: string | null,
    emailTemplateName?: string | null,
    discountObjectNum?: string | null,
    secondaryTender?: string | null,
    EnableLoyalty?: boolean | null,
    emailName?: string | null,
    emailLocation?: string | null,
    emailPhone?: string | null,
    tips?:  Array< {
      __typename: "Tip",
      id: string,
      amount: number,
      type: string,
      default: boolean,
    } | null > | null,
    serviceFee?:  {
      __typename: "ServiceFee",
      amount: number,
      type: string,
    } | null,
    soSocialEarnRatio?: number | null,
    soSocialRedeemRatio?: number | null,
    paymentMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    refundMerchants?:  Array< {
      __typename: "paymentMerchant",
      channel?: string | null,
      merchant_id?: string | null,
      access_code?: string | null,
      enc_key?: string | null,
      url?: string | null,
      env?: string | null,
    } | null > | null,
    applePayMerchantIdentifier?: string | null,
    isMPGS?: boolean | null,
    retryAfter?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnCreateUserConceptsSubscription = {
  onCreateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnUpdateUserConceptsSubscription = {
  onUpdateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnDeleteUserConceptsSubscription = {
  onDeleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnDeleteAttachmentSubscription = {
  onDeleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnCreateFeatureSubscription = {
  onCreateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnUpdateFeatureSubscription = {
  onUpdateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnDeleteFeatureSubscription = {
  onDeleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnCreateAdminRoleSubscription = {
  onCreateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnUpdateAdminRoleSubscription = {
  onUpdateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnDeleteAdminRoleSubscription = {
  onDeleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnCreateAdminGroupSubscription = {
  onCreateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnUpdateAdminGroupSubscription = {
  onUpdateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnDeleteAdminGroupSubscription = {
  onDeleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnCreateMediaSubscription = {
  onCreateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnUpdateMediaSubscription = {
  onUpdateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnDeleteMediaSubscription = {
  onDeleteMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnCreateTableSubscription = {
  onCreateTable?:  {
    __typename: "Table",
    id: string,
    tableCode: string,
    accountID: string,
    conceptID: string,
    simphonyID: string,
    name: string,
    status?: string | null,
    updatedAt?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    currentOrder?:  {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastClosedAt?: string | null,
    lastPaymentStatus?: string | null,
    lastUsedCash?: number | null,
    tableCurrentOrderId?: string | null,
  } | null,
};

export type OnUpdateTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnUpdateTableSubscription = {
  onUpdateTable?:  {
    __typename: "Table",
    id: string,
    tableCode: string,
    accountID: string,
    conceptID: string,
    simphonyID: string,
    name: string,
    status?: string | null,
    updatedAt?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    currentOrder?:  {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastClosedAt?: string | null,
    lastPaymentStatus?: string | null,
    lastUsedCash?: number | null,
    tableCurrentOrderId?: string | null,
  } | null,
};

export type OnDeleteTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnDeleteTableSubscription = {
  onDeleteTable?:  {
    __typename: "Table",
    id: string,
    tableCode: string,
    accountID: string,
    conceptID: string,
    simphonyID: string,
    name: string,
    status?: string | null,
    updatedAt?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    currentOrder?:  {
      __typename: "Order",
      id: string,
      accountID: string,
      conceptID: string,
      paymentID?: string | null,
      paid?: boolean | null,
      serviceFee?: number | null,
      tips?: number | null,
      paidAmount?: number | null,
      refundedAmount?: number | null,
      remainingAmount?: number | null,
      tableSimphonyID: string,
      CheckId?: string | null,
      CheckNumber?: string | null,
      CheckSeqNumber?: string | null,
      CheckOpenTime?: string | null,
      CheckCloseTime?: string | null,
      totalOrderAmount?: number | null,
      SubTotal?: number | null,
      Tax?: number | null,
      serviceCharge?: number | null,
      Due?: number | null,
      Payment?: number | null,
      AdditionalPayment?: number | null,
      TableOpenTime?: string | null,
      MenuItems:  Array< {
        __typename: "MenuItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } >,
      DiscountItems?:  Array< {
        __typename: "DiscountItem",
        DetailType?: string | null,
        DetailLink: string,
        obj?: string | null,
        Total?: number | null,
        RevCtrID?: string | null,
        mTaxCollectedTotal?: number | null,
        Name?: string | null,
        SalesCount?: number | null,
        paidQTY?: number | null,
        pcnt?: number | null,
        totalAmount?: number | null,
        price?: number | null,
      } > | null,
      usedCash?: number | null,
      ratings?:  Array< {
        __typename: "Rating",
        id: string,
        rating: number,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastClosedAt?: string | null,
    lastPaymentStatus?: string | null,
    lastUsedCash?: number | null,
    tableCurrentOrderId?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    accountID: string,
    conceptID: string,
    paymentID?: string | null,
    paid?: boolean | null,
    serviceFee?: number | null,
    tips?: number | null,
    paidAmount?: number | null,
    refundedAmount?: number | null,
    remainingAmount?: number | null,
    tableSimphonyID: string,
    CheckId?: string | null,
    CheckNumber?: string | null,
    CheckSeqNumber?: string | null,
    CheckOpenTime?: string | null,
    CheckCloseTime?: string | null,
    totalOrderAmount?: number | null,
    SubTotal?: number | null,
    Tax?: number | null,
    serviceCharge?: number | null,
    Due?: number | null,
    Payment?: number | null,
    AdditionalPayment?: number | null,
    TableOpenTime?: string | null,
    MenuItems:  Array< {
      __typename: "MenuItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } >,
    DiscountItems?:  Array< {
      __typename: "DiscountItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      pcnt?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } > | null,
    usedCash?: number | null,
    ratings?:  Array< {
      __typename: "Rating",
      id: string,
      rating: number,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    accountID: string,
    conceptID: string,
    paymentID?: string | null,
    paid?: boolean | null,
    serviceFee?: number | null,
    tips?: number | null,
    paidAmount?: number | null,
    refundedAmount?: number | null,
    remainingAmount?: number | null,
    tableSimphonyID: string,
    CheckId?: string | null,
    CheckNumber?: string | null,
    CheckSeqNumber?: string | null,
    CheckOpenTime?: string | null,
    CheckCloseTime?: string | null,
    totalOrderAmount?: number | null,
    SubTotal?: number | null,
    Tax?: number | null,
    serviceCharge?: number | null,
    Due?: number | null,
    Payment?: number | null,
    AdditionalPayment?: number | null,
    TableOpenTime?: string | null,
    MenuItems:  Array< {
      __typename: "MenuItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } >,
    DiscountItems?:  Array< {
      __typename: "DiscountItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      pcnt?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } > | null,
    usedCash?: number | null,
    ratings?:  Array< {
      __typename: "Rating",
      id: string,
      rating: number,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    accountID: string,
    conceptID: string,
    paymentID?: string | null,
    paid?: boolean | null,
    serviceFee?: number | null,
    tips?: number | null,
    paidAmount?: number | null,
    refundedAmount?: number | null,
    remainingAmount?: number | null,
    tableSimphonyID: string,
    CheckId?: string | null,
    CheckNumber?: string | null,
    CheckSeqNumber?: string | null,
    CheckOpenTime?: string | null,
    CheckCloseTime?: string | null,
    totalOrderAmount?: number | null,
    SubTotal?: number | null,
    Tax?: number | null,
    serviceCharge?: number | null,
    Due?: number | null,
    Payment?: number | null,
    AdditionalPayment?: number | null,
    TableOpenTime?: string | null,
    MenuItems:  Array< {
      __typename: "MenuItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } >,
    DiscountItems?:  Array< {
      __typename: "DiscountItem",
      DetailType?: string | null,
      DetailLink: string,
      obj?: string | null,
      Total?: number | null,
      RevCtrID?: string | null,
      mTaxCollectedTotal?: number | null,
      Name?: string | null,
      SalesCount?: number | null,
      paidQTY?: number | null,
      pcnt?: number | null,
      totalAmount?: number | null,
      price?: number | null,
    } > | null,
    usedCash?: number | null,
    ratings?:  Array< {
      __typename: "Rating",
      id: string,
      rating: number,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePaymentRequestSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentRequestFilterInput | null,
};

export type OnCreatePaymentRequestSubscription = {
  onCreatePaymentRequest?:  {
    __typename: "PaymentRequest",
    id: string,
    usedCash?: number | null,
    user?: string | null,
    conceptId?: string | null,
    tableSimphonyId?: string | null,
    CheckNum?: string | null,
    CheckSeq?: string | null,
    payment?:  {
      __typename: "PaymentRequestDetails",
      type?: string | null,
      amount?: number | null,
      billAmount?: number | null,
      tips?: number | null,
      serviceCharge?: number | null,
      items?:  Array< {
        __typename: "PaymentRequestItem",
        DetailLink?: string | null,
        qty?: number | null,
      } | null > | null,
      userEmail?: string | null,
      paymentMethod?: string | null,
    } | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    hasDiscounts?: boolean | null,
    discountAmount?: number | null,
    offerAmount?: number | null,
    familyGroupAmount?: number | null,
    isOffer?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePaymentRequestSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentRequestFilterInput | null,
};

export type OnUpdatePaymentRequestSubscription = {
  onUpdatePaymentRequest?:  {
    __typename: "PaymentRequest",
    id: string,
    usedCash?: number | null,
    user?: string | null,
    conceptId?: string | null,
    tableSimphonyId?: string | null,
    CheckNum?: string | null,
    CheckSeq?: string | null,
    payment?:  {
      __typename: "PaymentRequestDetails",
      type?: string | null,
      amount?: number | null,
      billAmount?: number | null,
      tips?: number | null,
      serviceCharge?: number | null,
      items?:  Array< {
        __typename: "PaymentRequestItem",
        DetailLink?: string | null,
        qty?: number | null,
      } | null > | null,
      userEmail?: string | null,
      paymentMethod?: string | null,
    } | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    hasDiscounts?: boolean | null,
    discountAmount?: number | null,
    offerAmount?: number | null,
    familyGroupAmount?: number | null,
    isOffer?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePaymentRequestSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentRequestFilterInput | null,
};

export type OnDeletePaymentRequestSubscription = {
  onDeletePaymentRequest?:  {
    __typename: "PaymentRequest",
    id: string,
    usedCash?: number | null,
    user?: string | null,
    conceptId?: string | null,
    tableSimphonyId?: string | null,
    CheckNum?: string | null,
    CheckSeq?: string | null,
    payment?:  {
      __typename: "PaymentRequestDetails",
      type?: string | null,
      amount?: number | null,
      billAmount?: number | null,
      tips?: number | null,
      serviceCharge?: number | null,
      items?:  Array< {
        __typename: "PaymentRequestItem",
        DetailLink?: string | null,
        qty?: number | null,
      } | null > | null,
      userEmail?: string | null,
      paymentMethod?: string | null,
    } | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    hasDiscounts?: boolean | null,
    discountAmount?: number | null,
    offerAmount?: number | null,
    familyGroupAmount?: number | null,
    isOffer?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
};

export type OnCreatePaymentSubscription = {
  onCreatePayment?:  {
    __typename: "Payment",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    tableID?: string | null,
    tableName?: string | null,
    channel?: string | null,
    tableOpenTime?: string | null,
    usedCash?: number | null,
    billAmount?: number | null,
    tipAmount?: number | null,
    serviceFees?: number | null,
    order_id?: string | null,
    tracking_id?: string | null,
    bank_ref_no?: string | null,
    order_status?: string | null,
    failure_message?: string | null,
    payment_mode?: string | null,
    card_name?: string | null,
    status_code?: string | null,
    status_message?: string | null,
    currency?: string | null,
    Amount?: string | null,
    billing_name?: string | null,
    billing_address?: string | null,
    billing_city?: string | null,
    billing_state?: string | null,
    billing_zip?: string | null,
    billing_country?: string | null,
    billing_tel?: string | null,
    billing_email?: string | null,
    merchant_param1?: string | null,
    merchant_param2?: string | null,
    merchant_param3?: string | null,
    merchant_param4?: string | null,
    merchant_param5?: string | null,
    merchant_param6?: string | null,
    mer_amount?: string | null,
    eci_value?: string | null,
    card_holder_name?: string | null,
    bank_receipt_no?: string | null,
    bank_qsi_no?: string | null,
    updatedSimphony?: boolean | null,
    updatedDBOrder?: boolean | null,
    errorMessage?: string | null,
    updatedSosocialWallet?: boolean | null,
    retryExecutionArn?: string | null,
    refunded?: boolean | null,
    refundedAmount?: number | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    earned?: boolean | null,
    isRegistered?: boolean | null,
    deleted?: string | null,
    CheckNum?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment?:  {
    __typename: "Payment",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    tableID?: string | null,
    tableName?: string | null,
    channel?: string | null,
    tableOpenTime?: string | null,
    usedCash?: number | null,
    billAmount?: number | null,
    tipAmount?: number | null,
    serviceFees?: number | null,
    order_id?: string | null,
    tracking_id?: string | null,
    bank_ref_no?: string | null,
    order_status?: string | null,
    failure_message?: string | null,
    payment_mode?: string | null,
    card_name?: string | null,
    status_code?: string | null,
    status_message?: string | null,
    currency?: string | null,
    Amount?: string | null,
    billing_name?: string | null,
    billing_address?: string | null,
    billing_city?: string | null,
    billing_state?: string | null,
    billing_zip?: string | null,
    billing_country?: string | null,
    billing_tel?: string | null,
    billing_email?: string | null,
    merchant_param1?: string | null,
    merchant_param2?: string | null,
    merchant_param3?: string | null,
    merchant_param4?: string | null,
    merchant_param5?: string | null,
    merchant_param6?: string | null,
    mer_amount?: string | null,
    eci_value?: string | null,
    card_holder_name?: string | null,
    bank_receipt_no?: string | null,
    bank_qsi_no?: string | null,
    updatedSimphony?: boolean | null,
    updatedDBOrder?: boolean | null,
    errorMessage?: string | null,
    updatedSosocialWallet?: boolean | null,
    retryExecutionArn?: string | null,
    refunded?: boolean | null,
    refundedAmount?: number | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    earned?: boolean | null,
    isRegistered?: boolean | null,
    deleted?: string | null,
    CheckNum?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
};

export type OnDeletePaymentSubscription = {
  onDeletePayment?:  {
    __typename: "Payment",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    tableID?: string | null,
    tableName?: string | null,
    channel?: string | null,
    tableOpenTime?: string | null,
    usedCash?: number | null,
    billAmount?: number | null,
    tipAmount?: number | null,
    serviceFees?: number | null,
    order_id?: string | null,
    tracking_id?: string | null,
    bank_ref_no?: string | null,
    order_status?: string | null,
    failure_message?: string | null,
    payment_mode?: string | null,
    card_name?: string | null,
    status_code?: string | null,
    status_message?: string | null,
    currency?: string | null,
    Amount?: string | null,
    billing_name?: string | null,
    billing_address?: string | null,
    billing_city?: string | null,
    billing_state?: string | null,
    billing_zip?: string | null,
    billing_country?: string | null,
    billing_tel?: string | null,
    billing_email?: string | null,
    merchant_param1?: string | null,
    merchant_param2?: string | null,
    merchant_param3?: string | null,
    merchant_param4?: string | null,
    merchant_param5?: string | null,
    merchant_param6?: string | null,
    mer_amount?: string | null,
    eci_value?: string | null,
    card_holder_name?: string | null,
    bank_receipt_no?: string | null,
    bank_qsi_no?: string | null,
    updatedSimphony?: boolean | null,
    updatedDBOrder?: boolean | null,
    errorMessage?: string | null,
    updatedSosocialWallet?: boolean | null,
    retryExecutionArn?: string | null,
    refunded?: boolean | null,
    refundedAmount?: number | null,
    percentageArray?: number | null,
    soSocialConversion?: number | null,
    earned?: boolean | null,
    isRegistered?: boolean | null,
    deleted?: string | null,
    CheckNum?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    paymentReceipt: string,
    rating: string,
    review?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    paymentReceipt: string,
    rating: string,
    review?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    accountID: string,
    conceptID: string,
    orderID: string,
    paymentReceipt: string,
    rating: string,
    review?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnCreateUserIPSubscription = {
  onCreateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnUpdateUserIPSubscription = {
  onUpdateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnDeleteUserIPSubscription = {
  onDeleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnCreateMainCategorySubscription = {
  onCreateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    menuPDF: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnUpdateMainCategorySubscription = {
  onUpdateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    menuPDF: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnDeleteMainCategorySubscription = {
  onDeleteMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    menuPDF: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
  } | null,
};
